































import { useUser } from '~/modules/customer/composables/useUser';
import { useUiState } from '@/composables/useUiState';
import { cartGetters } from '~/getters';
import useCart from '~/modules/checkout/composables/useCart';
import { computed } from '@nuxtjs/composition-api';
import { useIsMobile } from '@/helpers/mobileDevices';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    isAuthenticated
  } = useUser();
  const {
    isMobile
  } = useIsMobile();
  const {
    cart
  } = useCart();
  const {
    currentViewInModal,
    setCurrentViewInModal
  } = useUiState();
  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  return {
    emit,
    isAuthenticated,
    currentViewInModal,
    setCurrentViewInModal,
    totalItems
  };
};
export default __sfc_main;
