












































import useWishlist from '~/modules/wishlist/composables/useWishlist';
import type { Product } from '~/modules/catalog/product/types';
import { PropType, computed } from '@nuxtjs/composition-api';
import { useIsMobile } from '@/helpers/mobileDevices';
import { useUiState, useUser } from '~/composables';
import { SfIcon } from '@storefront-ui/vue';
const __sfc_main = {};
__sfc_main.props = {
  product: {
    type: ([Object, null] as PropType<Product>),
    default: null
  },
  text: {
    type: String,
    required: true
  },
  info: {
    type: Boolean,
    required: true,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    isMobile
  } = useIsMobile();
  const {
    isAuthenticated
  } = useUser();
  const {
    isInWishlist,
    addItem,
    removeItem
  } = useWishlist();
  const {
    toggleLoginModal
  } = useUiState();
  const isWishlistPage = computed(() => window.location.href.includes('wishlist') ? true : false);
  const addItemToWishlist = async product => {
    await (isInWishlist({
      product
    }) ? removeItem({
      product
    }) : addItem({
      product
    }));
  };
  return {
    isMobile,
    isAuthenticated,
    isInWishlist,
    removeItem,
    toggleLoginModal,
    isWishlistPage,
    addItemToWishlist
  };
};
__sfc_main.components = Object.assign({
  SfIcon
}, __sfc_main.components);
export default __sfc_main;
