










import { useContext, useRouter, computed } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import arrowIcon from '@/assets/icons/icon-arrow-right.svg';
const __sfc_main = {};
__sfc_main.props = {
  text: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    app
  } = useContext();
  const router = useRouter();
  const {
    toggleLoginModal
  } = useUiState();
  const props = __props;
  const iconPath = computed(() => require(`@/assets/icons/${props.icon}.svg`));
  const handleRedirectToLink = () => {
    const registerRoute = app.localeRoute({
      path: props.link
    });
    router.push(registerRoute);
    toggleLoginModal(false);
  };
  return {
    arrowIcon,
    iconPath,
    handleRedirectToLink
  };
};
export default __sfc_main;
