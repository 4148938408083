export function removeAccents(input: string) {
    const accentMap = {
        à: 'a',
        â: 'a',
        ä: 'a',
        á: 'a',
        ã: 'a',
        å: 'a',
        è: 'e',
        ê: 'e',
        ë: 'e',
        é: 'e',
        í: 'i',
        ì: 'i',
        î: 'i',
        ï: 'i',
        ò: 'o',
        ô: 'o',
        ö: 'o',
        ó: 'o',
        õ: 'o',
        ø: 'o',
        ù: 'u',
        û: 'u',
        ü: 'u',
        ú: 'u',
        ç: 'c',
        ñ: 'n',
        ß: 'ss',
      };
      return input.replace(
        /[àâäáãåèêëéíìîïòôöóõøùûüúçñß]/g,
        function (matched) {
          return accentMap[matched];
        }
      );
  }
  
