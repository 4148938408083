





































import CategoryProductPrice from "~/modules/catalog/category/components/views/CategoryProductPrice.vue";
import { handleDiscountPercentage } from "~/modules/catalog/product/helpers/handleDiscountPercentage";
import { PropType, ref, useRouter, onMounted, watch } from "@nuxtjs/composition-api";
import { useProductGallery } from "~/modules/catalog/product/composables/useProductGallery";
import { useHidePriceStore } from "~/components/Header/PriceHider/hidePriceStore";
import type { Product } from "~/modules/catalog/product/types";
import ProductHeader from '../ProductCard/ProductHeader.vue';
import { useProductCard } from '../ProductCard/productCard';
import { useIsMobile } from '@/helpers/mobileDevices';
import { SfSkeleton } from "@storefront-ui/vue";
import { SfColor } from "@storefront-ui/vue";
const __sfc_main = {};
__sfc_main.props = {
  product: {
    type: ([Object, null] as PropType<Product>),
    default: null
  },
  hideAddToCart: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const {
    isMobile
  } = useIsMobile();
  const router = useRouter();
  const configurableProduct = ref(null);
  const {
    orderMediaGalleryByPosition
  } = useProductGallery(configurableProduct);
  const isLoading = ref(false);
  const selectedImage = ref([]);
  const selectedImageHover = ref([]);
  const mediaGallery = ref(props.product ? props.product?.media_gallery : {});
  const {
    getGalleryByColor,
    isNewProduct,
    getColorConfiguration,
    setIsOutOfStock
  } = useProductCard();
  const newProduct = ref(false);
  const discountProduct = ref(false);
  const productImages = ref([]);
  const currentImage = ref("");
  const configurableColorOptionsArray = ref([]);
  const colorConfiguration = ref(null);
  const selectedColor = ref(null);
  const totalColor = ref(4);
  const outOfStock = ref(false);
  const hidePriceStore = useHidePriceStore();
  const priceIsVisible = ref(hidePriceStore.hidePrice);
  hidePriceStore.$subscribe((mutation, state) => {
    priceIsVisible.value = state.hidePrice;
  });
  watch(() => props.product, newProduct => {
    Object.assign(props.product, newProduct);
  });
  onMounted(async () => {
    isLoading.value = true;
    totalColor.value = isMobile ? 2 : 4;
    colorConfiguration.value = await getColorConfiguration(props.product.configurable_options?.[1]?.values, null, null);
    configurableColorOptionsArray.value = colorConfiguration.value?.[0];
    selectedColor.value = colorConfiguration.value?.[1];
    productImages.value = await getGalleryByColor(selectedColor.value, props.product.variants, mediaGallery.value);
    selectedImage.value = props.product?.thumbnail.url;
    selectedImageHover.value = productImages?.value?.[1]?.url || selectedImage.value;
    currentImage.value = selectedImage.value;
    newProduct.value = await isNewProduct(props.product);
    discountProduct.value = handleDiscountPercentage(props.product) ? true : false;
    await orderMediaGalleryByPosition(props.product);
    isLoading.value = false;
    outOfStock.value = await setIsOutOfStock(props.product.variants);
    await getInfoProductHeader();
  });
  const onHover = () => {
    const sizeElement = document.querySelector(`.product-size.${CSS.escape(props.product.name.replace(' ', '_'))}`);
    if (sizeElement) {
      sizeElement.style.display = 'flex';
      currentImage.value = selectedImageHover.value;
    }
  };
  const onLeave = () => {
    const sizeElement = document.querySelector(`.product-size.${CSS.escape(props.product.name.replace(' ', '_'))}`);
    if (sizeElement) {
      sizeElement.style.display = 'none';
      currentImage.value = selectedImage.value;
    }
  };
  const viewsMoreColor = (colorUid: string | null) => {
    router.push({
      path: props.product.commonProps.link.fullPath,
      query: {
        color: colorUid
      }
    });
    emit('update:closeSearchModal');
  };
  const redirectToDetailProduct = (url: string) => {
    router.push(url);
    emit('update:closeSearchModal');
  };
  const getInfoProductHeader = () => {
    if (outOfStock.value) {
      return {
        info: true,
        text: 'Out of stock'
      };
    }
    const discountText = handleDiscountPercentage(props.product);
    if (discountText) {
      return {
        info: true,
        text: discountText
      };
    }
    if (newProduct.value) {
      return {
        info: true,
        text: 'News'
      };
    }
    return {
      info: false,
      text: ''
    };
  };
  return {
    props,
    isLoading,
    currentImage,
    configurableColorOptionsArray,
    selectedColor,
    totalColor,
    priceIsVisible,
    onHover,
    onLeave,
    viewsMoreColor,
    redirectToDetailProduct,
    getInfoProductHeader
  };
};
__sfc_main.components = Object.assign({
  SfSkeleton,
  ProductHeader,
  CategoryProductPrice,
  SfColor
}, __sfc_main.components);
export default __sfc_main;
