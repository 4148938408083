/* eslint-disable  */

export default {
  FAQ: 'FAQ',
  'All dress': 'toutes les robes',
  'All accessories': 'tous les accessoires',
  Search_my_order: 'Chercher ma commande',
  Contact_us: 'Contactez-nous',
  'contact-text':
    "Vous avez une question, besoin d'un avis ou d'un recommandation, d'aide sur un produit ou votre commande, retrouvez ici le meilleur moyen de nous contacter.",
  Stores: 'Boutiques',
  'Welcome to the proffessional version': 'Bienvenue sur la version professionnelle',
  'Welcome to the VIP version': 'Bienvenue sur la version VIP',
  Help: 'Aide et contact',
  'Size guide': 'Guide des tailles',
  'Delivery & returns': 'Livraison & Retours',
  'About Us': 'A propos de nous',
  "Shops & partners": "Boutiques & partenaires",
  'Our lookbook': 'Notre lookbook',
  Events: 'Nos événements',
  Collections: 'Nos collections',
  Information: 'Informations',
  'Legal notice': 'Mentions légales',
  'Terms of Sale': 'Conditions générales de vente',
  'Privacy Policy': 'Politique de données confidentielles',
  'Cookies management': 'Gestion de cookies',
  Social: 'Nos réseaux sociaux',
  'follow us': 'Suivez-nous',
  'Quick delivery': 'Livraison rapide ',
  'Protected parcels': 'Colis protégés',
  'Secure payment': 'Paiement sécurisé',
  Size_guides: 'Consulter les tailles',
  'Back in stock alert': 'Alerte remise en stock',
  Items: 'Articles',
  Total: 'Total',
  'Add to cart': 'Ajouter au panier',
  stock01: 'Attention ! Stock Limité, Seulement',
  restant: 'restant',
  restants: 'restants',
  'Your item': 'Votre article',
  'You can add up to 10 items to your wish list. Please clear the items before adding them.':
    "Vous pouvez ajouter jusqu'à 10 articles à votre liste de souhaits. Veuillez effacer les éléments avant de les ajouter.",
  'Returns instruction': 'Pour effectuer un retour',
  'Would love you too': 'Vous aimerez aussi...',
  'Discover Promulias': 'Découvrez promulias',
  Cart: 'Panier',
  'Order summary': 'Détails de la commande',
  Quantity: 'Quantité',
  Subtotal: 'Sous-total',
  'Tax label': 'Plus TVA 20%',
  Discount: 'Promotion',
  'Shipping begin': 'Livraison à partir de',
  'Enter promo code': 'Appliquer un code de réduction',
  'Go to checkout': 'Procéder au paiement',
  'Find your favorite items': 'Retrouver vos articles préférés',
  'Go to my wishlist': 'Aller dans ma Wishlist',
  'Your bag is empty': 'Votre panier est vide',
  Empty: "Il semblerait que vous n'ayez pas encore ajouté d'articles au panier.",
  Start_shopping: 'Commencer votre shopping',
  'Return to cart': 'Retourner au panier',
  Shipping: 'Livraison',
  'First Name': 'Prénom',
  'Last Name': 'Nom de famille',
  'Number, label and route': 'Numéro, libellé et voie',
  'Entrance, building and apartment': 'Entrée, bâtiment et appartement',
  'phone_format': 'Le format du numéro de téléphone est incorrect',
  City: 'Ville',
  'State/Province': 'Région',
  Country: 'Pays',
  'Zip-code': 'Code postal',
  Phone_number: 'Numéro de téléphone',
  'Add new address': 'Ajouter une nouvelle adresse',
  'Use this address as my default one.': 'Utiliser cette adresse par défaut.',
  'Select shipping method': 'Sélectionner la méthode de livraison',
  'Continue to payment': 'Continuer vers le paiement',
  Payment: 'Paiement',
  'My billing and shipping address are the same': "Mon adresse de facturation est la même que l'adresse de livraison",
  agree: 'En cochant cette case, vous reconnaissez avoir pris connaissance des ',
  'Terms and conditions': 'Conditions générales de vente',
  'agree suite': ' et vous en acceptez les termes.',
  'Pay for order': 'Payer ma commande',
  'Change my delivery': 'Modifier ma livraison',
  'Thank you for your order!': 'Nous vous remercions de votre commande !',
  Order_No: 'Numéro de commande',
  'Your Purchase': 'Votre Achat',
  'Successful placed order':
    "Votre commande a été passée avec succès. Vous pouvez vérifier l'état de votre commande depuis votre espace client. Vous recevrez un e-mail de confirmation avec les détails de votre commande. \n\n * En cas d'une livraison à l'adresse de votre choix, vous serez informé par email lors de la prise en charge de votre colis par le transporteur.  \n\n * En cas d'un retrait en dépôt, vous recevrez un email de confirmation lorsque votre commande sera prête à être retirée.",
  'See my order': 'Voir ma commande',
  'Download your bill': 'Télécharger votre facture', //pas sur le site
  'Download my invoice PRO FARMA': 'Télécharger ma facture PRO FARMA', // pas de bouton
  'Do you have a questions ?': 'Avez-vous des questions ?',
  'Contact_us': 'Contactez-nous',
  'Write a review ?': 'Ecrire un avis ?',
  "Choose address": 'Veuillez sélectionner ou créer votre adresse de livraison',
  "Shipping address": 'Adresse de livraison',
  'Share it': 'Partagez-le !',
  'Continue to purchase': 'Continuer vos achats',
  'Sign in': 'Se connecter',
  Forgot_Password: 'Mot de passe oublié',
  Forgot_password_content_modal_1: 'Saisissez l\'adresse e-mail que vous utilisez pour vous connecter.',
  Forgot_password_content_modal_2: 'Un e-mail vous sera envoyé pour réinitialiser votre mot de passe',
  Forgot_Password_Modal_Email: 'Votre adresse e-mail :',
  forgotPasswordConfirmation:
    "Merci! Si un compte est enregistré avec l'e-mail {0}, vous trouverez un message avec un lien de réinitialisation du mot de passe dans votre boîte de réception.",
  Receive_email: "Recevoir l'email",
  Reset_Password: 'Réinitialisation du mot de passe',
  Register_New_Password: 'Renseignez votre nouveau mot de passe.',
  Required_1: 'Il doit contenir au minimum 8 caractères, 1 majuscule,',
  Required_2: '1 minuscule et 1 chiffre.',
  'Your password': 'Votre mot de passe',
  Confirm_Password: 'Confirmer le mot de passe',
  'Reset your password': 'Réinitialiser votre mot de passe',
  Register: "S'inscrire",
  Civility: 'Civilité',
  'Your email-adress': 'Votre adresse e-mail',
  Password: 'Mot de passe',
  Your_Society: 'Informations de la société',
  Name_Society: 'Nom de la société',
  Adress_Office: 'Adresse postale du siège social',
  Position_Held: 'Poste occupé',
  Store_Adress: 'Adresse boutique de vente',
  Website: 'Site internet',
  TVA: 'Numéro de TVA intracommunautaire',
  Import_File: 'Importer des documents (KBIS)',
  '* Required fields': '* Champs obligatoires',
  Personal_data: 'Modifier mon profil',
  Profil: 'Modifier mes informations personnelles',
  Save: 'Enregistrer',
  'Add new adress': 'Ajouter une nouvelle adresse',
  'Current Password': 'Mot de passe actuel',
  'Current Email': 'Votre adresse électronique actuelle',
  'The password must be at least 8 characters long and must contain at least: 1 uppercase, 1 lowercase letter and 1 number':
    'Votre mot de passe doit contenir au minimun 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre.',
  'New Password': 'Nouveau mot de passe',
  "Passwords don't match": 'Les mots de passe ne correspondent pas',
  'Addresses details': 'Mes adresses',
  'Default Billing Address': 'Adresse de facturation',
  'Default Shipping Address': 'Adresse de livraison',
  Change: 'Changer',
  Delete: 'Supprimer',
  'Add the address': 'Ajouter une adresse',
  'Update the address': "Mettre à jour l'adresse",
  'Contact details updated':
    "Gérez toutes les adresses de livraison que vous souhaitez (lieu de travail, adresse personnelle, etc.). Ainsi, vous ne devrez pas saisir manuellement l'adresse de livraison pour chaque commande.",
  'Set as default shipping': 'Définir comme adresse de livraison par défaut',
  'Set as default billing': 'Définir comme adresse de facturation par défaut',
  'My orders': 'Mes commandes',
  'Orders': 'Commande',
  Ask_for_billing:
    'Pour toute demande de facturation, veuillez vous rapprocher de l’équipe de Promulias depuis WhatsApp ou en formulant votre demande depuis la page de contact.',
  'Order Details': 'Détails de commande',
  'My returns': 'Mes retours',
  'Return of order': 'Retour de commande',
  'Details and status orders':
    'Vérifiez les détails et le statut de vos commandes dans la boutique en ligne. Vous pouvez demander un retour de votre commande.',
  'Payment date': 'Date de paiement',
  Amount: 'Montant',
  Status: 'Statut',
  'Read more': 'En savoir plus',
  'Read less': 'Lire moins',
  'You currently have no orders': "Vous n'avez actuellement aucun historique de commande.",
  Product: 'Produit',
  Date: "Date d'achat",
  'Shipping address': 'Adresse de livraison',
  'Shipping method': 'Mode de livraison',
  'Payment method': 'Mode de paiement',
  Reorder: 'Commander à nouveau',
  'Reorder-asking': 'Voulez-vous renouveler cette commande ?',
  'Reorder-number': 'Numéro de commande : ',
  'Reorder-agree': 'Valider',
  'Reorder-cancel': 'Annuler',
  'Download my proforma': 'Télécharger mon proforma',
  'Reorder-error': 'Une erreur est survenue lors de la récupération de votre commande',
  Consult: 'Consulter vos retours',
  'Conform Product': 'Produit conforme à mes attentes',
  'This field is not correct.': 'Ce champs est incorrect.',
  'Defective Product': 'Produit défectueux',
  'Have a question about a return?': 'Vous avez une question concernant votre retour ?',
  'Write to us': 'Ecrivez-nous',
  Storelocator1: 'Les boutiques Promulias à Paris',
  'Storelocator-text':
    'Nous vous accueillons chaleureusement au sein de nos boutiques de robe de mariée à Paris Promulias Sentier et Promulias Popincourt. L’idéal est de réserver un créneau à l’avance pour une séance d’essayage dans nos showrooms pour que notre équipe sur place puisse prendre le temps de vous conseiller. ',
  Storelocator2: 'Les boutiques de nos revendeurs hors de Paris',
  'Storelocator-text2':
    'Si vous n’habitez pas en région parisienne, pas de panique ! De manière à rendre nos collections de robes accessibles à tout un chacun, nous collaborons avec des boutiques de revendeurs en province dans de nombreuses villes de France. Vous trouverez sans aucun doute une boutique à proximité de chez vous. Découvrez la liste de nos boutiques partenaires.',
  locate_me: 'Me localiser',
  Promulias_Shop: 'Boutique Promulias',
  Partner_Shop: 'Boutique partenaire',
  Store_find: 'Boutiques trouvées : ',
  Schedule: 'Horaires',
  Week: 'Du lundi au vendredi de 9h à 19h',
  'Week-end': 'Le samedi de 9h à 20h',
  Sunday: 'Fermé le dimanche',
  Appointment: ' Prendre rendez-vous',
  Wishlist: 'Wishlist',
  'No favorites yet': 'Pas encore de favoris',
  'Looks like you haven’t added any items to the Wishlist.':
    'Sélectionnez votre article préféré en cliquant sur le coeur. Vous pourrez le retrouver ici.',
  Question: 'Une question ?',
  Whatsapp: 'Nous rejoindre sur WhatsApp',
  'Whatsapp Text': 'Besoin de réponse rapides ? Posez-nous vos questions sur WhatsApp ! Ecrivez-nous !',
  'Whatsapp QRCode': 'Scannez le QR Code !',
	"Select the subject of your request": "Sélectionnez l'objet de votre message",
  Category: 'Catégories',
  Email_address: 'Adresse e-mail',
  Topic: 'Objet',
  Particular: 'Je suis un particulier',
  Professional: 'Je suis un professionnel',
  Order: 'Commande',
  Go_back: 'Retour',
  'Quest?': 'Question',
	Message: 'Message',
  'privacy-policy': 'Politique des données confidentielles',
  'Invalid Email Contact_us': "L'adresse e-mail n'est pas valide",
  'Invalid Phone Number Contact_us': 'Le numéro de téléphone renseigné semble incorrect',
  Select_a_choice: 'Selectionner une catégorie',
  'Accept CGU': 'Veuillez accepter les ',
  'Promulias in Milan': 'Promulias à Milan',
  'our history': 'Notre histoire',
  'Categories of questions': 'Catégories',
  'Answers to your questions by category': 'Les réponses à vos questions par catégories :',
  "We can't find products matching the selection.": 'Nous ne trouvons pas de produits correspondant à la sélection.',
  Page_not_found: 'Page not found',
  Sadly: "Malheureusement, nous n'avons pas trouvé la page que vous recherchez.",
  'Back to homepage': "Retour à l'accueil",
  'Go back': 'Retour',
  'See more': 'Voir plus',
  'Learn more': 'En savoir plus',
  'the news': 'Les nouveautés Promulias !',
  'the dress': "Nos robes d'exception !",
  'Accept cookies': 'Accepter les cookies',
  'Cookies text': 'Nous utilisons des ',
  Cookies: 'cookies',
  'Cookies text2':
    " qui aident le site web à fonctionner et nous aident à comprendre comment interagir avec lui. Nous utilisons ces cookies pour vous offrir une expérience utilisateur améliorée et personnalisée. Si vous continuez à utiliser le site web, nous supposons que vous êtes d'accord.",
  Accept: 'Accepter',
  'Yes, i delete this article': 'Oui, je supprime cet article.',
  'Are you sure you would like to remove this item from the shopping cart?': 'Souhaitez-vous supprimer cet article ?',
  Price: 'Prix',
  'About us': 'A propos',
  About_us: 'Qui sommes-nous ?',
  Our_values: 'Nos valeurs, nos engagements',
  About_subtitlecontent1:
    'Promulias est une entreprise innovante et créative fondée à Paris par un directeur général dynamique déjà présent dans plusieurs pays européens.',
  About_subtitlecontent2:
    'Promulias diffère largement de ses concurrents avec un grand nombre de produits dans une gamme complète de tailles et de couleurs (robes de cocktail et de soirée).',
  About_subtitlecontent3: "Comme son créateur, Promulias est très à l'écoute de ses clients.",
  About_subtitlecontent4:
    "Son équipe s'inscrit dans la même lignée que celle du fondateur, avec un suivi qualité très sérieux, de la sélection des modèles jusqu'à la fabrication.",
  About_subtitlecontent5: "De plus, la sélection des distributeurs contribue à la création d'un réseau de qualité.",
  About_subtitlecontent6: "Promulias n'est qu'au début d'une belle histoire dont les plus belles pages seront écrites avec vous.",
  Account: 'Mon compte',
  'Add to compare': 'Ajouter pour comparer',
  'Add to Wishlist': 'Ajouter à la wishlist',
  'Additional Information': 'Informations supplémentaires',
  'Allow order notifications': 'Autoriser les notifications de commande',
  Apply: 'Appliquer',
  'Attention!': 'Attention!',
  Billing: 'Facturation',
  'Billing address': 'Adresse de facturation',
  Brand: 'Marque',
  Cancel: 'Annuler',
  'Change password your account':
    'Si vous voulez changer le mot de passe pour accéder à votre compte, entrez les informations suivantes : votre adresse électronique actuelle est',
  'Clear all': 'Effacer tout',
  Color: 'Couleur',
  'Commercial information': "et je suis d'accord pour recevoir des informations commerciales de Promulias.",
  'Contact Text':
    'Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.',
  'Continue to billing': 'Continuer vers la facturation',
  'Continue to shipping': "Continuer vers l'expédition",
  'Cookies Policy': 'Politique de cookies',
  'Create an account': 'Créer un compte',
  'Customer Reviews': 'Avis des clients',
  'Customer service': 'Service client',
  Departments: 'Départements',
  Description: 'Description',
  Done: 'Finie',
  Download: 'Télécharger',
  'Download all': 'Tout télécharger',
  Edit: 'Modifier',
  'Empty bag': 'Panier vide',
  'Enjoy your free account': 'Profitez de ces avantages avec votre compte gratuit !',
  Filters: 'Filtres',
  "FILTRES": "FILTRES",
  "EN VENDETTE": "IN THE SPOTLIGHT",
  'Find out more': 'En savoir plus',
  Feedback: 'Votre avis compte pour nous. Faîtes nous savoir comment nous pouvons nous améliorer.',
  'Forgotten password?': 'Mot de passe oublié ?',
  'Go back shopping': 'Retour au shopping',
  'Go back to shop': 'Retour à la boutique',
  Guarantee: 'Garantie',
  'Street Name': 'Nom de la rue',
  'House/Apartment number': 'Numéro de maison/appartement',
  hide: 'Cacher',
  Home: 'Accueil',
  'I agree to': "J'accepte",
  'I confirm subscription': 'Je confirme ma souscription',
  'I want to create an account': 'Je souhaite créer un compte',
  'Info after order':
    "Vous pouvez vous connecter à votre compte en utilisant l'e-mail et le mot de passe définis précédemment. Sur votre compte, vous pouvez modifier les données de votre profil, vérifier l'historique des transactions, modifier l'abonnement à la newsletter.",
  Instruction1: 'Prends soin de moi',
  Instruction2: "Juste ici pour les instructions d'entretien ?",
  Instruction3: 'Ouais, nous le pensions',
  'It was not possible to request a new password, please check the entered email address.':
    "Il n'a pas été possible de demander un nouveau mot de passe, veuillez vérifier votre adresse email.",
  Item: 'Article',
  Kidswear: 'Vêtements pour enfants',
  'Let’s start now – we’ll help you': 'Commençons maintenant – nous vous aiderons',
  'Log into your account': 'Connectez-vous à votre compte',
  Login: 'Connexion',
  'login in to your account': 'Connectez-vous à votre compte',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    "Il semblerait que vous n'ayez pas encore ajouté d'articles au panier. Commencez à acheter pour le remplir.",
  'Make an order': 'Commander',
  'Manage addresses':
    "Gérez toutes les adresses de livraison que vous souhaitez (lieu de travail, adresse personnelle, etc.). Ainsi, vous ne devrez pas saisir manuellement l'adresse de livraison pour chaque commande.",
  'Manage billing addresses':
    "Gérez toutes les adresses de facturation que vous souhaitez (lieu de travail, domicile...) Ainsi vous n'aurez pas à saisir manuellement l'adresse de facturation à chaque commande.",
  'Manage shipping addresses':
    "Gérez toutes les adresses de livraison que vous souhaitez (lieu de travail, domicile...) Ainsi vous n'aurez pas à saisir manuellement l'adresse de livraison à chaque commande.",
  'We did not find anything for': "Nous n'avons rien trouvé pour",
  'Match it with': 'Correspond à',
  'Men fashion': 'Mode homme',
  Menu: 'Menu',
  'My Cart': 'Mon panier',
  New_Products: 'Nouveaux produits',
  'No account': "Vous n'avez pas encore de compte ? ",
  or: 'ou',
  'or fill the details below': 'ou remplissez les détails ci-dessous',
  'Other products you might like': "D'autres produits que vous pourriez aimer",
  'Out of stock': 'Rupture de stock',
  'Password Changed': "Le mot de passe a été modifié avec succès. Vous pouvez maintenant revenir à la page d'accueil et vous connecter.",
  'Payment & Delivery': 'Paiement & Livraison',
  'Payment methods': 'Méthodes de paiement',
  'Personal details': 'Informations personnelles',
  'Please type your current password to change your email address.': 'Veuillez saisir votre mot de passe actuel pour modifier votre adresse email.',
  Invalid_First_Name: 'Prénom invalide',
  First_Name: 'Prénom',
  Last_Name: 'Nom',
  Postal_Code: 'Code Postal',
  Invalid_Last_Name: ' Nom invalide',
  Invalid_email: "L'adresse e-mail renseignée ne semble pas correcte",
  Invalid_phone_number: 'Numéro de téléphone invalide',
  CGU: "J'accepte les ",
  'CGU-CTA': "Conditions Générales d'Utilisation",
  Open: 'Ouvrir',
  Privacy: 'Confidentialité',
  'Product suggestions': 'Suggestions de produits',
  Products: 'Produits',
  'Products found': 'Produits trouvés',
  'Purchase terms': "Conditions d'achat",
  'Quality in the details': 'Qualité dans les détails',
  'Read all reviews': 'Lire les avis',
  'Read and understand': "J'ai lu et compris la",
  'Read reviews': 'Avis',
  'Register today': 'Enregistrez-vous maintenant',
  'Remove Address': "Supprimer l'adresse",
  'Remove from Wishlist': 'Supprimer de la wishlist',
  'Repeat Password': 'Répéter le mot de passe',
  'Review my order': 'Vérifier ma commande',
  'Same as shipping address': "Identique à l'adresse de livraison",
  'Save changes': 'Enregistrer les modifications',
  'Save for later': 'Enregistrer pour plus tard',
  Save_Password: 'Enregistrer le mot de passe',
  Search: 'Rechercher',
  'Search for items': 'Rechercher des articles',
  'Search results': 'Résultats de la recherche',
  'Sections that interest you': 'Sections qui vous intéressent',
  'See all results': 'Voir tous les résultats',
  'Select payment method': 'Sélectionner la méthode de paiement',
  'Send my feedback': 'Envoyer mon avis',
  'Set up newsletter':
    'Configurez votre newsletter et nous vous enverrons chaque semaine des informations sur les nouveaux produits et les tendances des sections que vous avez sélectionnées.',
  'Share your look': 'Partagez votre look',
  'Shipping details': 'Détails de livraison',
  'Show on page': 'Afficher sur la page',
  Show: 'Montrer',
  'Show more': 'Voir plus',
  'Show less': 'Voir moins',
  'Sign Up for Newsletter': 'Je veux recevoir la newsletter et j’en accepte les termes',
  'Sort by': 'Trier par',
  "Sort": "Trier",
  'Sort: Default': 'Par défaut',
  'Sort: Name A-Z': 'Par nom A-Z',
  'Sort: Name Z-A': 'Par nom Z-A',
  'Sort: Price from high to low': 'Par prix décroissant',
  'Sort: Price from low to high': 'Par prix croissant',
  'Start shopping': 'Commencez à acheter',
  Subscribe: 'Souscrire',
  'Subscribe to newsletter': "S'inscrire à la newsletter",
  subscribeToNewsletterModalContent:
    'Après vous être inscrit à la newsletter, vous recevrez des offres spéciales et des messages de Promulias par e-mail. Nous ne vendrons ni ne distribuerons votre e-mail à aucun tiers à aucun moment. Veuillez consulter notre {0}.',
  'Subtotal price': 'Prix sous-total',
  'Thank You Inbox':
    "Si le message n'arrive pas dans votre boîte de réception, essayez une autre adresse e-mail que vous avez peut-être utilisée pour vous inscrire.",
  'Total items': 'Total articles',
  'Total price': 'Prix total',
  'Update password': 'Mettre à jour le mot de passe',
  'Update personal data': 'Mettre à jour les données personnelles',
  'Use your personal data':
    'Chez Promulias, nous attachons une grande importance aux questions de confidentialité et nous nous engageons à protéger les données personnelles de nos utilisateurs. En savoir plus sur la façon dont nous prenons soin et utilisons vos données personnelles dans le',
  'User Account': 'Compte utilisateur',
  View: 'Voir',
  'View details': 'Voir les détails',
  'Learn more': 'En savoir plus',
  'Vue Storefront Next': 'Vue Storefront Next',
  'Who we are': 'Qui nous sommes',
  'Women fashion': 'Mode femme',
  'You are not authorized, please log in': "Vous n'avez pas l'authorisation, veuillez vous connecter",
  'You can unsubscribe at any time': 'Vous pouvez vous désabonner à tout moment',
  'You haven’t searched for items yet': "Vous n'avez pas encore recherché d'articles",
  'Your current email address is': 'Votre adresse email actuelle est',
  'Your email': 'Votre email',
  'The email field must be a valid email': 'Le champ email doit être une adresse email valide',
  'You have submitted no reviews': "Vous n'avez pas encore soumis de commentaires",
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.':
    'La connexion au compte était incorrecte ou votre compte est temporairement désactivé. Veuillez patienter et réessayer plus tard.',
  'A customer with the same email address already exists in an associated website.': 'Un client avec la même adresse e-mail existe',
  'Invalid email': 'Email invalide',
  'SUMMER COLLECTION {year}': 'COLLECTION ÉTÉ {year}',
  'Colorful summer dresses are already in store': "Les vêtements d'été colorés sont déjà en magasin",
  'Prestige dress': 'Robe prestige',
  'Ribbon dress': 'Robe à ruban',
  Wedding: 'Mariage',
  Accessories: 'Accessoires',
  Dresses: 'Vêtements',
  'Cocktail & Party': 'Cocktail & Fête',
  'Linen Dresses': 'Vêtements de lin',
  'T-Shirts': 'T-Shirts',
  'The office life': "La vie de l'agence",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.":
    'Trouvez de superbes robes de cocktail et robes de soirée pour femmes. Démarquez-vous avec des robes de cocktail en dentelle et métallisées de toutes vos marques préférées.',
  'Shop now': 'Achetez maintenant',
  'Explore now': 'Explorer',
  'Summer Sandals': "Sandales d'été",
  Submit: 'Envoyer',
  'Eco Sandals': 'Sandales écologiques',
  'Subscribe to Newsletters': "S'inscrire à la newsletter",
  'Be aware of upcoming sales and events. Receive gifts and special offers!':
    'Soyez au courant des ventes et événements à venir. Recevez des cadeaux et des offres spéciales!',
  'Fashion to take away': 'Mode à prendre',
  'Download our application to your mobile': 'Téléchargez notre application sur votre mobile',
  'Share Your Look': 'Partagez votre look',
  'My Account': 'Mon compte',
  'My profile': 'Mon profil',
  'Personal Details': 'Détails personnels',
  'My newsletter': 'Ma newsletter',
  'Log out': 'Se déconnecter',
  'My reviews': 'Mes commentaires',
  'Order history': 'Historique des commandes',
  'Order details': 'Détails de la commande',
  'My wishlist': 'Ma wishlist',
  'Password change': 'Modifier mot de passe',
  'Personal data': 'Données personnelles',
  'Your e-mail': 'Votre e-mail',
  'You are not authorized, please log in.': "Vous n'êtes pas autorisé, veuillez vous connecter.",
  'Go To Product': 'Aller au produit',
  'Change to list view': 'Changer en vue liste',
  'Change to grid view': 'Changer en vue grille',
  Returns: 'Retours',
  'Please select a country first': "Veuillez d'abord sélectionner un pays",
  'Invalid_number': 'Numéro invalide',
  'This field is required': 'Ce champ est requis',
  'The field should have 8-16 characters' : 'Le numéro de téléphone doit comporter entre 8 et 16 chiffres.',
  'The field should have at least 2 characters': 'Le champ doit contenir au moins 2 caractères',
  'The field should have at least 4 characters': 'Le champ doit contenir au moins 4 caractères',
  'The field should have at least 10 characters': 'Le champ doit contenir moins de 10 caractères',
  'The field should have less than 35 characters': 'Le champ doit contenir moins de 35 caractères',
  'The field should have at least 50 characters': 'Le champ doit contenir moins de 50 caractères',
  'New Products': 'Nouveaux produits',
  'no shipping methods available':
    "Il n'y a pas de méthodes d'expédition disponibles pour ce pays. Nous sommes désolés, veuillez essayer avec un autre pays.",
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.':
    "Une erreur s'est produite lors de la tentative de récupération des méthodes d'expédition. Nous sommes désolés, veuillez essayer avec des détails d'expédition différents.",
  'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.':
    "Une erreur s'est produite lors de la sélection de ce mode d'expédition. Nous sommes désolés, veuillez essayer avec une méthode d'expédition différente.",
  Find_my_order: 'Retrouver votre commande',
  'I am a professional': 'Je suis un professionnel',
  About: 'À propos',
  Lookbook: 'Lookbook',
  'Collect loyalty points': 'Cumulez des points de fidélité',
  'Subscribe to newsletter': "S'abonner à la newsletter",
  'your@mail-address.com': 'votre@adresse-mail.com',
  Size: 'Taille',
  'Your item is successfully added to cart.': 'Votre article est bien ajouté au panier.',
  'Sizes guides': 'Guides des tailles',
  'products in stock': 'produits en stock',
  'product in stock': 'produit en stock',
  'Sorry, this product is no longer available': 'Désolé, ce produit n’est plus disponible',
  'I am a professional': 'Je suis un professionnel',
  CDG: 'Conditions générales de vente de produits en ligne entre professionnels',
  PC: 'Mentions légales et politique de confidentialité',
  GUC: 'CGU',
  Sed: 'Sed porttitor lectus nibh ?',
  Lorem:
    'Nulla quis lorem ut libero malesuada feugiat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus suscipit tortor eget felis porttitor volutpat.',
  Find_order: 'Recherche de la commande',
  subcontentFO_1:
    "Recherche une seule commande en saisissant votre numéro de commande et l'adresse e-mail utilisée pour passer ma commande en ligne ou en magasin",
  Passwords_do_not_match: 'Les mots de passe ne correspondent pas',
  invalid_Password_Msg: 'Mot de passe invalide',
  Privacy_Policy: 'Politique de données confidentielles',
  faq: 'Foire Aux Questions',
  'No answers ?': 'Vous ne trouvez pas de réponse à vos questions ?',
  'Join to WeChat': 'Nous joindre sur WeChat',
  Feel_free_to_edit: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
  telephone: 'Téléphone',
  Personal_details: 'Détails personnels',
  Addresses_details: 'Mes adresses',
  Order_details: 'Détails des commandes',
  Import_file: 'Fichier OSS',
  'Cgu-link': " Conditions Générales d'Utilisation.",
  Password_change: 'Modifier le mot de passe',
  Personnal_Informations: 'Vos informations personnelles',
  Madam: 'Madame',
  Mister: 'Monsieur',
  Misses: 'Mademoiselle',
  Prom_account: 'du compte Promulias',
  Invalid_name: 'Nom invalide',
  'Invalid number': 'Numéro invalide',
  'Invalid email': 'Email invalide',
  'Password confirmed': 'Les mots de passe ne correspondent pas',
  'Invalid adress': 'Adresse invalide',
  'Invalid postalCode': 'Code postal invalide',
  'Invalid field': 'Champ invalide',
  'Password invalid': 'Mot de passe invalide',
  Male: 'Homme',
  Female: 'Femme',
  'Not Specified': 'Non précisé',
  'Required fields': 'Champs obligatoires',
  'The requested amount is not available': "La quantité demandée n'est pas disponible",
  lookbooktext:
    'Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
  Or: 'Ou',
  'city&zip': 'Ville ou code postal',
  type_zip_or_town: 'Renseignrer un code postal ou une ville',
  away_from_you: ' de distance',
  'store-locator_subcontent':
    'Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
  'Country code': 'Code pays',
  'Chose a country': 'Choisir un pays',
  'Phone number': 'Numéro de téléphone',
  'Example :': 'Exemple :',
  Pending_account_title: 'Votre compte est en attente',
  Pending_account_text:
    'Merci pour votre inscription ! Votre compte est en attente car notre équipe analyse les différentes informations transmises lors de votre inscription. Une fois vos informations validées, un mail vous sera envoyé et vous pourrez accéder au site.',
  Refused_account_title: "Votre compte n'a pas été approuvé",
  Refused_account_text:
    "Il semblerai que une ou plusieurs informations transmises lors de votre inscription ne soient pas conformes à nos critères de validation. Merci de nous contacter pour plus d'informations.",
  'Contact us': 'Contactez-nous',
  'Join us on WeChat': 'Nous joindre sur WeChat',
  'Join us on WeChat': 'Nous joindre sur WeChat',
  'Rejoin us on WeChat': 'Nous rejoindre sur WeChat',
  'Refusal reason': 'Raison de refus',
  'An error occurred': "Une erreur s'est produite",
  error: 'Une erreur est survenue',
  Reorder: 'Renouveler votre commande',
  'Reorder-asking': 'Voulez-vous renouveler cette commande ?',
  'Reorder-number': 'Numéro de commande : ',
  'Reorder-agree': 'Valider',
  'Reorder-cancel': 'Annuler',
  'Reorder-error': 'Une erreur est survenue lors de la récupération de votre commande',
  'Return to cart': 'Retour au panier',
  'Read more...': 'Lire plus...',
  'Please note there is only': 'Attention il reste seulement',
  delivery_informations: 'Informations de livraison',
  delivery_informations_text: 'Vous trouverez ici, toutes nos solutions de livraison à domicile ou en point relais.',
  delivery_price: 'Livraison gratuite dès X €',
  metropolitan_France_only: 'France métropolitaine uniquement.',
  corse: 'Livraison en Corse:complément de 3€ de délai supplémentaire de 2jours.',
  'mondial-relay_delivery': 'Livraison standard en point relais - 3 à 5jours(ouvrés) - 4,90€',
  colissimo_delivery: 'Livraison standard à domicile - 2 à 4 jours(ouvrés) - 5,90€.',
  chronopost_delivery: 'Livraison rapide à domicile - 24 à 72h(jours ouvrés) - 9,90€.',
  'Welcome to the professional version': 'Bienvenue dans la version professionnelle',
  'No answers to your questions? Chat with us on WeChat! Write to us! Contact us now!':
    'Pas de réponses à vos questions ? Echangez avec nous sur WeChat ! Ecrivez-nous ! Contactez-nous !',
  'Contact text': 'Besoin de réponses rapides ? Posez-nous vos questions sur WhatsApp ! Ecrivez-nous !',
  'Contact wechat': 'Pas de réponses à vos questions ? Echangez avec nous sur WeChat ! Ecrivez-nous ! Contactez-nous !',
  'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number':
    'Votre mot de passe doit contenir au minimun 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre.',
  'Password confirmation does not match': 'Le mot de passe ne correspond pas',
  'Continue to Payment': 'Suivant',
  'Use this address': 'Utiliser cette adresse',
  'Use this address as my default one': 'Utiliser cette adresse comme adresse par défaut',
  'has been successfully removed from your cart.': 'a été correctement supprimé de votre panier.',
  Categories: 'Catégories',
  'Your email adress': 'Votre adresse e-mail',
  'Your password': 'Votre mot de passe',
  Event: 'Évènement',
  'No events': "Pas d'événements",
  'Prestige dress': 'Robe prestige',
  'Ribbon dress': 'Robe à ruban',
  Wedding: 'Mariage',
  Accessory: 'Accessoires',
  'Invalid zipcode': 'Code postal invalide',
  'Invalid city': 'Nom de ville invalide',
  'Invalid lastname': 'Nom de famille invalide',
  'Discover Wedding Dress': 'Découvrez notre collection de robe de mariée',
  'Wedding description':
    'Chercher sa robe de mariée est une aventure aussi exaltante que complexe ! Notre boutique de robes haut de gamme vous propose des modèles de prêt-à-porter adaptables à toutes les morphologies et aux finitions précises.',
  'Our delivery methods': 'Nos modes de livraison :',
  'Your message has been sent': 'Votre message a bien été envoyé',
  'To the address of your choice': 'À l’adresse de votre choix :',
  'description list 1': 'Livraison sous 24 à 48h en France Métropolitaine.',
  'description list 2': 'Dans la limite de 70 Kg par commande.',
  'By withdrawal in our Promulias stores': 'En retrait dans nos boutiques Promulias :',
  'description list 3': '55 rue Popincourt, 75011 Paris',
  'description list 4': "111 rue d'Aboukir, 75002 Paris",
  'How to return an item': 'Comment retourner un article ?',
  'description returns 1':
    "Quelle que soit la raison pour laquelle vous n'êtes pas satisfait par votre achat, si vous souhaitez nous retourner un produit en accord avec nos conditions générales de vente voici les conditions à remplir :",
  'description list 5': "Tous les articles doivent être dans leur emballage d'origine avec les étiquettes de produit intactes.",
  'description list 6': "Tous les articles doivent être non portés, non utilisés, non lavés et dans leur état d'origine.",
  'description list 7': 'Les reçus originaux devraient être inclus.',
  'description returns 2':
    'Rendez-vous sur WhatsApp ou WeChat et contactez nos équipes pour débuter votre retour. Les retours sont à votre charge sauf en cas de produit défectueux.',
  'Chat Content': 'Notre service client vous réponds et vous accompagne de 9h à 18h du lundi au vendredi',
  'WhatsApp Title': 'Démarrez votre retour via WhatsApp',
  'WeChat Content': 'Besoin de réponses rapides ? Posez-nous vos questions sur WeChat !',
  'WhatsApp Content': ' Besoin de réponses rapides ? Posez-nous vos questions sur WhatsApp !',
  'WeChat Title': 'Démarrez votre retour via WeChat',
  No_Favorite_Yet: 'Pas encore de favoris',
  Add_Favorites: 'Sélectionnez votre article préféré en cliquant sur le coeur. Vous pourrez le retrouver ici.',
  'return product':
    "Rendez-vous sur votre compte et depuis l'historique de vos commandes initiez un retour et contactez nos équipes. Les retours sont à votre charge sauf en cas de produit défectueux.",
  'Our elegant collections':
    'Promulias vous propose un large choix de robes aux tissus et formes variées. Chacune y trouvera une façon unique de sublimer sa silhouette pour se rendre à une soirée élégante.',
  'Explore our lookbook': 'Explorez notre lookbook',
  'Questions ?': 'Des questions ?',
  'Questions content':
    "Vous avez une question, besoin d'un avis ou d'une recommendation, d'aide sur un produit ou votre commande, rendez-vous sur notre page de contact.",
  'faq-legend':
    'Vous avez une question relative à votre achat ou vos produits Promulias qui vous brûle les lèvres ? Gagnez du temps en trouvant une réponse en quelques clics. Pour toute question concernant vos données personnelles et obtenir des instructions sur l’accès ou la suppression de vos données conservées, consultez notre Politique de données confidentielles.',
  'Your bill': 'Votre facture Proforma',
  'proforma-paragraph':
    "Votre facture Proforma est disponible en téléchargement ci-dessous et dans votre espace client. \n\n * En cas d'un paiement par virement, votre commande passera en préparation dès reception de la confirmation bancaire. \n\n * En cas d'un paiement par Carte Bancaire, votre commande passera automatiquement en préparation. \n\n\n Votre facture définitive sera jointe au colis.",
  'Questions content':
    "Vous avez une question, besoin d'un avis ou d'une recommendation, d'aide sur un produit ou votre commande, rendez-vous sur notre page de contact.",
  Particular: 'Je suis un particulier',
  'No article': "Pas d'article enregistré",
  'The user password was changed successfully updated!': 'Le mot de passe de l’utilisateur a été modifié avec succès !',
  'Online, Promulias offers you a wide range of dresses and accessories.':
    "En ligne, Promulias vous offre une large gamme de robes et d'accessoires.",
  'Through our wide catalogue of prestige dresses, collections dresses, ribbon dresses, wedding dresses, dresses for events, gala dresses, evening dresses, cocktail dresses, family party dresses, Dresses in promotion, dresses novelties the occasion will be given you to choose the style, the color or the size of your dress through the many photos, filters and details of our products.':
    "Au travers de notre large catalogue de robes prestige, robes collections, robes à ruban, robes de mariage, robes pour des évènements, robes de gala, robes de soirée, robes de cocktail, robes de fêtes familiales, robes en promotion, robes nouveautés l'occasion vous sera donnée de choisir le style, la couleur ou encore la taille de votre robe au travers des nombreuses photos, de filtres et de détails de nos produits.",
  'Discover our coloured chiffon, silk or lace dresses and choose the right length!':
    'Découvrez nos robes de couleur en mousseline, en soie ou en dentelle et choisissez la longueur adaptée !',
  'The advantage of buying your wedding dress online at Promulias is to benefit from the largest choice of size and silhouette but also from free shipping throughout France.':
    "L'avantage d'acheter votre robe de mariée en ligne chez Promulias, c'est de bénéficier du plus grand choix de taille et de silhouette mais aussi de la livraison gratuite dans toute la France.",
  'The Promulias customer service is available directly on whatsapp and wechat and answers you very quickly. Choosing a Promulias dress is opting for a designer dress at the price of ready-to-wear.':
    'Le service client Promulias est disponible directement sur whatsapp et wechat et vous repond très rapidement. Choisir une robe Promulias c’est opter pour une robe de créateur au prix du prêt-à-porter.',
  'Besides a nice collection of wedding and evening dresses, we offer for sale a wide selection of accessories and especially jewelry and shoes to refine your wedding outfit.':
    'Outre une jolie collection de robes de mariée et de soirée, nous proposons à la vente une large sélection d’accessoires et notamment de bijoux et de chaussures pour peaufiner votre tenue de mariage.',
  'Thanks to the different viewing angles of the photos, you can more easily project yourself.':
    'Grâce aux différents angles de vues des photos, vous pouvez plus facilement vous projeter.',
  and: ' et ',
  'By validating your order, you acknowledge having read the': 'En validant votre commande, vous reconnaissez avoir pris connaissance des',
  'and you accept the terms.': 'et vous acceptez les conditions.',
  ORDER: 'COMMANDE',
  reference: 'Référence',
  designation: 'Désignation',
  'P.U. HT': 'P.U. HT',
  'net amount': 'Montant HT',
  'total amount': 'Mt tot TTC',
  'Payment by transfer to the following bank account': 'Réglement par virement sur le compte bancaire suivant',
  bank: 'Banque',
  'bank code': 'Code Banque',
  'branch code': 'Code guichet',
  'account number': 'Numéro de compte',
  Key: 'Clé',
  address: 'Adresse:',
  'Account Owner Name': 'Nom de propriétaire du compte',
  'IBAN code': 'Code IBAN',
  'BIC/SWIFT code': 'Code BIC/SWIFT',
  'Total excluding tax': 'Total HT',
  'Shipping costs excluding tax': 'Frais de port HT',
  'Total VAT': 'Total TVA',
  'total TTC': 'Total TTC',
  'NET TO PAY': 'NET A PAYER',
  'card number': 'numéro de carte',
  'card number invalid': 'numéro de carte invalide',
  'CVV is required': 'Le CVV est requis',
  month: 'mois',
  year: 'année',
  'The card has expired': 'La carte est expirée',
  'all field required': 'Tous les champs sont requis',
  'An error occurred during payment': 'Votre paiement est refusé, veuillez vérifier les informations saisies ou utiliser un autre moyen de paiement',
  'Quick delivery description': "En 2 à 3 jours pour toute l'Union Européenne*",
  'Protected parcels description': 'Emballages solides et sécurisés',
  Confidentiality: 'Politique de données confidentielles',
  AllProductsFromCategory: 'Tous les produits',
  'remaining product.': ' produit restant.',
  'remaining products.': ' produits restants.',
  'Out of stock!': 'En rupture de stock !',
  'Tracking number': 'Numéro de suivi',
  'You have a question, need a review or recommendation, help on a product or your order, find here the best way to contact us.':
    "Vous avez une question, besoin d'un avis ou d'une recommandation, d'aide sur un produit ou votre commande, trouvez ici le meilleur moyen de nous contacter.",
  'notification save': 'Modification enregistrée',
  'Are you sure you want to empty your cart?': 'Êtes-vous sûr de vouloir vider votre panier ?',
  'Yes, I empty my basket': 'Oui, je vide mon panier',
  'One is missing the size of your product': 'Veuillez selectionner la taille de votre produit',
  'Your item is not successfully added to cart.': "Ce produit n'est plus disponible, veuillez choisir une autre couleur ou taille",
  'Sold out': '- Épuisé',
  Company: 'Société',
  'Be the first to leave a review': 'Soyez le premier à laisser un avis.',
  'Title Review': 'Titre',
  Review: 'Avis',
  'Add Review': 'Ajouter un avis',
  Name: 'Nom',
  'mail not existe': "Cette adresse email n'existe pas, veuillez saisir une adresse email valide.",
  'error product': 'Une erreur a été détectée lors de la récupération du produit, merci de réessayé ultérieurement.',
  'succes subscribe alert stock': 'Vous recevrez une alerte lors de la remise en stock.',
  'error subscribe alert stock': 'Une erreur est survenue, merci de vérifier votre adresse ou de réessayé ultérieurement.',
  'Receive one': 'Recevoir une',
  'Alert Stock': 'alerte stock',
  'alert me to stock': 'Recevoir une alerte de remise en stock',
  'Payment by CB': "Paiement par carte bancaire",
  'Order is being generated':'La commande est en cours de génération...',
  'Mail is being sent': 'Le courrier est en cours d\'envoi...',
  "News": "Nouveautés",
  "Long dresses": "Robes Longues",
  "Short dresses": "Robes Courtes",
  'Vider le panier': 'Vider le panier',
  'No_Order' : 'Numéro de commande',
  "Our collection": "Nos collections",
  "Professional space": "Espace professionnel",
  "Book a rental appointment": "Prendre rendez-vous pour la location",
  "newletter info": "Inscrivez-vous à notre liste pour recevoir nos nouveautés et nos événements privés",
  "newsletter subscribe error": 'Une erreur s\'est produite lors de l\'inscription à la newsletter, merci de vérifier que vous n\'êtes pas déjà inscrit.',
  "newsletter subscribe success": 'Votre adresse mail a été ajoutée avec succès.',
  'join promulias': 'Rejoignez Promulias',
  'newletter info 2': 'Envie d\'être à jour sur nos actualités et nos promotions ? Abonnez-vous à notre newletter et restez connecté !',
  'newsletter picture': 'image de couverture pour la newsletter',
  'Particular space': 'Espace particulier',
  'discover': "découvrir",
  'robes-courtes': 'robe courte',
  'robes-longues': 'robe longue',
  'combinaison': 'combinaison',
  'prestige': 'prestige',
  'collection': 'collection',
  'promlife': 'promlife',
  'mariage': 'mariage',
  'News collection': 'Nouvelle collection',
  'News collection text': 'Le plus grand choix de robes et d’accessoires',
  'ball dress': 'robe de ball',
  'bridesmaid dress': 'robe de demoiselle d\'honneur',
  'wedding dress': 'robe de mariée',
  'Category Section Title': 'Des Robes pour tous les styles',
  'discover all dresses': 'Découvrir toutes nos robes',
  'login text1': 'Renseignez votre email et votre mot de passe pour vous identifier .',
  'login text2': 'Vous êtes un particulier ?',
  'Click here': 'Cliquez ici.',
  'Language': 'Langue',
  'Languages': 'Langues',
  "the Promulias universe": 'Suivez-nous',
  'mother':'mère',
  'fur':'fourrure',
  'Jewelry': 'Bijoux',
  'All dresses': 'Toutes les robes',
  'Invalid_date': 'Date invalide',
  'Event text': 'Découvrez les événements exclusifs de la marque Promulias',
  'Our events': 'Nos évents',
  'our events text header': 'Chez Promulias, nous ne créons pas seulement des robes de soirée, nous créons des moments inoubliables. Découvrez tous nos événements exclusifs juste ici.',
  'url du site : ' : 'url du site : ',
  'phone : ' : 'téléphone : ',
  'help-search': 'aide',
	'Frequent searches': 'Recherches fréquentes',
	'Popular articles': 'Articles populaires',
	'Contact us': 'nous contacter',
	'returns & exchanges': 'retours & échanges',
  'Popular articles search': 'Les plus recherchés',
	'Need help ?' : "Besoin d'aide ?",
	'Ask your question or provide keywords' : 'Posez votre question ou indiquez des mots-clés',
	'product' : 'produits',
	'Delivery' : 'livraison',
	'Returns, Exchanges and Refunds' : "Retours, échanges et remboursements",
	'Select your category' : 'Choisissez votre catégorie',
	'Do you have a question' : 'Vous avez une question relative à votre achat ou vos produits Promulias qui vous brûle les lèvres ? Gagnez du temps en trouvant une réponse en quelques clics. Pour toute question concernant vos données personnelles et obtenir des instructions sur l’accès ou la suppression de vos données conservées, consultez notre',
	'privacy policy' : 'Politique de données confidentielles',
	'Search Results For' : 'Resultats de recherche pour',
	'Frequently Asked Questions' : 'Questions fréquentes',
	'No results found, please try another keyword' : 'Aucun resultat trouvé, veuillez entrez une nouvelle recherche',
  'Order' : 'Commande',
  'No results found' : 'Aucun résultat trouvé, veuillez entrer un autre mot clé',
  'Name': 'Nom',
  'Clear': 'Effacer',
  'no search result': 'Aucun produit trouvé',
  'no search result text1': 'Aucune robe ne correspond à votre recherche.',
  'no search result text2': 'Essayez un autre mot-clé ou laissez-vous inspirer par nos recommandations.',
  'search product': 'Recherche',
  'Load more product': 'Charger plus de produits',
	'out of': 'sur',
	'items': 'articles',
  'My help center' : "Mon centre d'aide",
  'My favorites' : 'Mes favoris',
  'My personal information' : 'Mes informations personnelles',
  'My payment methods' : 'Mes modes de paiement',
  'Logout' : 'Déconnexion',
  'You may also like': 'Vous aimerez aussi',
  "Informations": 'Informations',
  "Delivery": "Livraison",
  "Reinsurance Delivery details": "Livraison sous 24 à 48h en Europe. Pour les livraisons en dehors de l'Europe, merci de nous contacter afin de convenir d’une méthode de livraison. Si vous êtes un client professionnel : La limite de poids de votre commande est de 250 Kg. Si vous êtes un client particulier : la limite de poids de votre commande est de 70 Kg.",
  "Returns, exchanges and refunds": "Retours, échanges et remboursements",
	"Reinsurance Returns, exchanges and refunds details": "Pour retourner un article, il vous suffit d’expédier le colis sous 14 jours via le transporteur de votre choix. Les frais de retour sont couverts à vos frais, y compris les droits et taxes pour les clients internationaux.",
  "About": "A propos",
  "Reinsurance About details": "Nos magasins sont situés au 55 rue popincourt 75011 Paris et au 111 rue d'Aboukir 75002 Paris. Nos boutiques partenaires peuvent être retrouvées sur la page “Boutiques. Vous pouvez contacter le service client à l’adresse contact@promulias.com ou en récupérant nos informations de contact en bas de page.",
  "Reviews": "Avis", 
  "Add review": "Ajouter un avis",
  "Delivery and returns": "Livraisons et retours",
  "Share": "Partager",
  "Check Sizes": "Guide des tailles",
  "Colors available": "Couleurs disponibles",
  'To sign up': 'S\'inscrire',
  'Be first to leave a review': 'Soyez le premier à laisser un avis',
  'Title': 'Titre',
	'You might like': 'Vous pourriez aimer',
  'Validate my cart': 'Valider mon panier',
  "it looks like you haven't added any items to the cart yet": 'Il semblerait que vous n\'ayez pas encore ajouté d\'articles au panier',
  'Your cart': 'Votre panier',
	'Summary': 'Récapitulatif',
	'Add a privilege code': 'Ajouter un code privilège',
	'Order value': 'Valeur de la commande',
	'Please remove out of stock products from your cart': 'Veuillez supprimer les produits en rupture de stock dans votre panier',
	'Code': 'Code',
	'Fast delivery': 'Livraison rapide',
	'Returns within 14 days': 'Retour sous 14 jours',
	'Secure payment': 'Paiement sécurisé',
	'Earn points': 'Cumuler des points',
  'modify': 'modifier',
  'validate': 'valider',
  'evening dresses': 'robe de soirée',
  'Order tracking': 'Suivi de commande',
  'Discover our products': 'Découvrez nos produits',
  'Reset': 'Réinitialiser',
	'delete filters': 'supprimer les filtres',
	'See products': 'Voir les produits',
  'order details N°': 'détails de la commande N°',
	'order date': 'Date de la commande',
	'Track package': 'Suivre le colis',
	'Download invoice': 'Télécharger la facture',
  'Order total': 'Total de la commande',
	'make a return': 'effectuer un retour',
	'To return an item, simply send the package within 14 days via the carrier of your choice to the following address:': 'Pour retourner un article, il vous suffit d’expédier le colis sous 14 jours via le transporteur de votre choix à l’adresse suivante : ',
	'Return shipping costs are covered at your expense, including duties and taxes for international customers. Contact us to arrange return shipping and fees.': 'Les frais de retour sont couverts à vos frais, y compris les droits et taxes pour les clients internationaux. Contactez-nous afin de convenir d’une expédition retour et des frais de retour.',
	'Find your nearest store': 'Trouver la boutique la plus proche',
	'The quickest and easiest way is to visit one of our Promulias stores, where you can choose between an exchange or an immediate refund.': 'Le plus simple et rapide est de vous rendre dans l’une de nos boutiques Promulias : vous pourrez alors choisir de procéder à un échange ou d’obtenir un remboursement immédiat.',
	'In store': 'en boutique',
	'Return': 'Retour',
  'You currently have no orders': 'Vous n’avez pas encore de commandes enregistrées',
  'Track the status of your current purchases and order history': 'Suivez l’état de vos achats en cours et l’historique de vos commandes',
  'Packages': 'Colis',
	'Order number': 'Commande n°',
	'See detail': 'Voir le détail',
  'Track Your Order': 'Accéder au suivi de commande',
  'Add or modify your delivery addresses': 'Ajouter ou modifier vos adresses de livraison',
	'Add an address': 'Ajouter une adresse',
	'Address': 'Adresse',
  'Address_book': "Carnet d'adresses",
	'Default address': 'Adresse par défaut',
	'Refunded': 'Remboursé',
  "Use your personal data": "Je souhaite rester informé(e) des actualités et offres promotionelles Promulias et j’en accepte les termes.Chez Promulias, nous attachons une grande importance aux questions de confidentialité et nous nous engageons à protéger les données personnelles de nos utilisateurs. En savoir plus sur la façon dont nous prenons soin et utilisons vos données personnelles dans la",
  'Add or change your contact details to update your account.': 'Ajoutez ou modifiez vos coordonnées pour mettre à jour votre compte.',
	'Personal information': 'Informations personnelles',
	'Change my password': 'Modifier mon mot de passe',
	'Newsletter Update Account Info': 'Je souhaite rester informé(e) des actualités et offres promotionelles Promulias et j’en accepte les termes.',
	'By email': 'Par email',
	'password-regex': 'Le mot de passe doit contenir au minimum : 8 caractères,1 majuscule, 1 minuscule, 1 caractère spécial, 1 chiffre',
  "New address": "Nouvelle adresse",
  'Your address has been successfully added.': 'Votre adresse a été ajoutée avec succès.',
	'Your address has been successfully updated.': 'Votre adresse a été mise à jour avec succès.',
	'An error occurred while adding your address. Please try again.': 'Une erreur est survenue lors de l\'ajout de votre adresse. Veuillez réessayer.',
	'An error occurred while updating your address. Please try again.': 'Une erreur est survenue lors de la mise à jour de votre adresse. Veuillez réessayer.',
	'Delete Address': 'Supprimer l\'adresse',
	'Are you sure you want to delete this address? This action cannot be undone.': 'Êtes-vous sûr(e) de vouloir supprimer cette adresse ? Cette action est irréversible.',
  'Promulias stores in Paris': 'Les boutiques Promulias à paris',
	'Promulias stores in Paris text': 'Nous vous accueillons chaleureusement au sein de nos boutiques de robe de mariée à Paris Promulias Sentier et Promulias Popincourt. L’idéal est de réserver un créneau à l’avance pour une séance d’essayage dans nos showrooms pour que notre équipe sur place puisse prendre le temps de vous conseiller.',
  'Our retailers\' stores outside Paris': 'Les boutiques de nos revendeurs hors de Paris',
  'Our retailers\' stores outside Paris text': 'Si vous n’habitez pas en région parisienne, pas de panique ! De manière à rendre nos collections de robes accessibles à tout un chacun, nous collaborons avec des boutiques de revendeurs en province dans de nombreuses villes de France. Vous trouverez sans aucun doute une boutique à proximité de chez vous. Découvrez la liste de nos boutiques partenaires.',
	'Reserve a slot': 'Réserver un créneau ',
	'Partner': 'Partenaire',
	'Opened': 'Ouvert',
	'Close to': 'Ferme à ',
	'Closed': 'Fermé',
	'Open to': 'Ouvre à ',
	'Our stores': 'Nos boutiques',
	'Address, town or zip code': 'Adresse, ville ou code postal',
	'Or Geolocate me': 'Ou Géolocalisez-moi',
	'Store': 'boutique',
	'nearby': 'à proximité',
  'Open now': 'Ouvert actuellement',
	'mark': 'marque',
	'Services': 'Services',
	'See results': 'Voir les résulats',
  'Click & collect ‧ Location': 'Click & collect ‧ Location',
  'E-réservation': 'E-réservation',
  'Shops and partners': 'Boutiques et partenaires',
  'To Register': 'Enregistrer',
  'Card number': 'Numéro de carte',
  'Home delivery': 'Livraison à domicile',
	'Pick up in store': 'Retrait en boutique',
	'Your order is confirmed': 'Votre commande est confirmée',
	'By clicking on "Confirm Payment," i accept the': 'En cliquant sur "Confirmer le paiement", j\'accepte les',
	'General Terms and Conditions of Sale': 'Conditions Générales de Vente',
	'Confirm payment': 'Confirmer le paiement',
	'CVV must be 3 or 4 digits': 'Le CVV doit contenir 3 ou 4 chiffres',
	'Please choose a year': 'Veuillez choisir une année',
	'Selected': 'Sélectionnée',
	'Select': 'Sélectionner',
  'Next': 'Suivant',
  'Delete entire cart': 'Supprimer tout le panier',
  'Cancel': 'Annuler',
  'Delete': 'Supprimer',
  'Find your favorite articles': 'Retrouvez vos articles préférés',
  'Go to my Wishlist': 'Aller à ma Wishlist',
  'Privilege code': 'Code privilège',
  'Confirm my order': 'Confirmer ma commande',
  'Remove': 'Supprimer',
  'Applied Coupon': 'Coupon appliqué',
  'Card number': 'Numéro de la carte',
  'Security code': 'Code de sécurité',
  'and more': 'et plus',
  'Month': 'Mois',
  'Year': 'Année',
  'By clicking on validate payment, i accept the': 'En cliquant sur valider le paiement, j\'accepte les',
  'Validate payment': 'Valider le paiement',
  'Promulias stores': 'Les boutiques Promulias',
  'Are you sure you want to delete all the items in your basket?': 'Êtes-vous sûr de vouloir supprimer tous les articles de votre panier ?',
  'Select an address': 'Sélectionner une adresse',
  'Date of birth': 'Date de naissance',
  'Total after discount': 'Total après réduction',
  'there are not stores results': 'Il n\'y a pas de boutique dans ce département ou cette ville.',
  'return to addresses list': 'Retourner à la liste de mes adresses',
  'Please choose a month': 'Choisissez un mois',
  'Select your payment method': 'Sélectionner votre mode de paiement',

};
