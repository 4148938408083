







































































































































import { SfProperty, SfImage, SfScrollable, SfSkeleton } from '@storefront-ui/vue';
import { ref, computed, useContext, useRouter, onBeforeMount, watch } from '@nuxtjs/composition-api';
import _debounce from 'lodash.debounce';
import { useUiNotification } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import { useUiState } from '~/composables';
import { useApi } from '~/composables/useApi';
import { useProductsWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import { ProductInterface } from '~/modules/GraphQL/types';
import type { CartItemInterface } from '~/modules/GraphQL/types';
import stockStatusEnum from '~/enums/stockStatusEnum';
import { cartGetters } from '~/getters';
import productsList from '../../../../customQueries/productsListQuery';
import { ProductRepository } from '../../../../repository/products.repository';
import InputQuantity from '../../../Cart/InputQuantity.vue';
import ProductDetail from '~/components/Cart/ProductDetail.vue';
import ProductCardVariantSearch from '../../../Products/Search/ProductCardVariantSearch.vue';
import DarkButton from '../../Button/DarkButton.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const visible = ref(false);
  const loading = ref(true);
  const router = useRouter();
  const {
    app
  } = useContext();
  const {
    query
  } = useApi();
  const {
    send: sendNotification
  } = useUiNotification();
  const {
    cart,
    removeItem,
    updateItemQty,
    load: loadCart,
    loading: loadingCart
  } = useCart();
  const {
    toggleLoginModal
  } = useUiState();
  const bestProducts = ref<ProductInterface[] | []>([]);
  const productsWithCommons = ref([]);
  const {
    productsWithCommonProductCardProps
  } = useProductsWithCommonProductCardProps(bestProducts);
  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  onBeforeMount(async () => {
    await loadCart();
    bestProducts.value = await getBestProductSkuSearch();
  });
  watch(productsWithCommonProductCardProps, productsWithCommonProduct => {
    if (productsWithCommonProduct && productsWithCommonProduct.length > 0) {
      productsWithCommons.value = productsWithCommonProduct;
      loading.value = false;
    }
  }, {
    immediate: true
  });
  const productRepository = new ProductRepository();
  const getBestProductSkuSearch = async () => {
    try {
      const bestProductSku = await productRepository.getBestProduct();
      const response = await query(productsList, {
        filter: {
          sku: {
            in: bestProductSku
          }
        }
      });
      return response?.products?.items || [];
    } catch (e) {
      console.error('Erreur lors de la récupération des produits:', e);
    }
  };
  const getTotalPrices = () => {
    const cartItems = cart.value.items;
    if (cartItems.length > 0) {
      return cartItems.reduce((total, item) => total + item.prices.row_total_including_tax.value, 0);
    }
    return 0;
  };
  const goToCartPage = async () => {
    await router.push(app.localePath({
      name: 'cart'
    }));
    toggleLoginModal();
  };
  const isInStock = product => {
    return cartGetters.getStockStatus(product) === stockStatusEnum.inStock;
  };
  const defineSpecialPrice = (product: CartItemInterface) => {
    const itemPrice = cartGetters.getItemPrice(product);
    const difference = itemPrice.regular - itemPrice.special;
    return difference > 0 ? app.$fc(itemPrice.special) : 0;
  };
  const getAttributes = product => {
    return product?.product?.configurable_options || [];
  };
  const getBundles = product => {
    return product?.product?.bundle_options?.map(b => b.values).flat() || [];
  };
  const delayedUpdateItemQty = _debounce(params => {
    const {
      product,
      quantity
    } = params;
    const maxQty = product.product.only_x_left_in_stock;
    if (quantity > maxQty) {
      updateItemQty({
        product,
        quantity: maxQty
      });
    } else {
      updateItemQty(params);
    }
  }, 1000);
  const actionRemoveItem = async product => {
    await removeItem({
      product
    });
    visible.value = false;
    sendNotification({
      id: Symbol('product_removed'),
      message: `${cartGetters.getItemName(product)} a été supprimé avec succès de votre panier.`,
      type: 'success',
      icon: 'check',
      persist: false,
      title: 'Product removed'
    });
  };
  const getName = productItem => {
    if (productItem.__typename !== 'SimpleCartItem') {
      return productItem.product.name;
    }
    const lastdashIndex = productItem.product.name.lastIndexOf('-');
    const lastCommaIndex = productItem.product.name.lastIndexOf(',');
    let firstIndex = Math.max(lastdashIndex, lastCommaIndex);
    if (firstIndex !== -1) {
      const indexOfName = productItem.product.name.substring(0, firstIndex).lastIndexOf('-');
      if (indexOfName !== -1) {
        return productItem.product.name.substring(0, indexOfName).trim();
      } else {
        return productItem.product.name.substring(0, firstIndex);
      }
    } else {
      const nameSplit = productItem.product.name.split(' ');
      return `${nameSplit[0]} ${nameSplit[1]}`;
    }
  };
  const getColorName = (productName: string) => {
    let color = productName.substring(productName.lastIndexOf('-') + 1, productName.lastIndexOf(',')).trim();
    if (color.includes('-')) {
      const colorArray = color.split('-');
      color = colorArray[colorArray.length - 2];
    }
    return color;
  };
  const getSize = (productName: string) => {
    let size = productName.substring(productName.lastIndexOf(',') + 1);
    if (size.includes('-')) {
      size = size.substring(size.lastIndexOf('-') + 1);
    }
    return size;
  };
  return {
    cartGetters,
    loading,
    cart,
    loadingCart,
    productsWithCommons,
    totalItems,
    getTotalPrices,
    goToCartPage,
    isInStock,
    defineSpecialPrice,
    getAttributes,
    getBundles,
    delayedUpdateItemQty,
    actionRemoveItem,
    getName,
    getColorName,
    getSize
  };
};
__sfc_main.components = Object.assign({
  ProductDetail,
  SfProperty,
  InputQuantity,
  SfScrollable,
  SfSkeleton,
  ProductCardVariantSearch,
  DarkButton,
  SfImage
}, __sfc_main.components);
export default __sfc_main;
