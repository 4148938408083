











const __sfc_main = {};
__sfc_main.props = {
  text: {
    type: String,
    required: true,
    default: 'Button'
  },
  textColor: {
    type: String,
    required: false,
    default: '#7B7B7B'
  },
  backgroundColor: {
    type: String,
    required: false,
    default: '#1E1E1E1A'
  },
  width: {
    type: String,
    required: false,
    default: 'auto'
  },
  height: {
    type: String,
    required: false,
    default: 'auto'
  },
  load: {
    type: Boolean,
    required: true,
    default: false
  },
  disable: {
    type: Boolean,
    required: false,
    default: false
  },
  dataCy: {
    type: String,
    required: true,
    default: 'gray_button_without_data-cy'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  return {};
};
export default __sfc_main;
