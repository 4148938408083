

























import { ref, computed, watch } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  qty: {
    type: [Number, String],
    default: 1
  },
  minQty: {
    type: Number,
    default: 1
  },
  maxQty: {
    type: Number,
    default: 10
  },
  isInStock: {
    type: String,
    default: 'IN_STOCK'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const selectedQuantity = ref(typeof props.qty === 'string' ? Number(props.qty) : props.qty);
  const availableQuantities = computed(() => {
    if (props.isInStock === 'OUT_OF_STOCK') {
      return [];
    }
    const min = props.minQty;
    const max = props.maxQty;
    const quantities: number[] = [];
    for (let i = min; i <= max; i++) {
      quantities.push(i);
    }
    return quantities;
  });
  watch(() => props.qty, newVal => {
    selectedQuantity.value = typeof newVal === 'string' ? Number(newVal) : newVal;
  });
  return {
    props,
    emit,
    selectedQuantity,
    availableQuantities
  };
};
export default __sfc_main;
