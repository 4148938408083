


































import { useUiState } from '@/composables/useUiState';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    toggleLoginModal,
    setCurrentViewInModal
  } = useUiState();
  const returnToLoginForm = () => {
    setCurrentViewInModal('AccountInModal');
  };
  return {
    toggleLoginModal,
    returnToLoginForm
  };
};
export default __sfc_main;
