



















import { ref } from '@nuxtjs/composition-api';
import { useUiState } from '../../../../composables/useUiState/index';
import GrayButton from '../../Button/GrayButton.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    setCurrentViewInModal
  } = useUiState();
  const isLoading = ref(false);
  const setOpenOrderTrackModal = () => {
    setCurrentViewInModal('OrderTrackInModal');
    emit('openOrderTrackModal');
  };
  return {
    isLoading,
    setOpenOrderTrackModal
  };
};
__sfc_main.components = Object.assign({
  GrayButton
}, __sfc_main.components);
export default __sfc_main;
