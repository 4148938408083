import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f1fec0b6 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "" */))
const _553dc7f7 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _729c45b8 = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _15964aae = () => interopDefault(import('../pages/Cgu.vue' /* webpackChunkName: "pages/Cgu" */))
const _157a1bac = () => interopDefault(import('../pages/Cgv.vue' /* webpackChunkName: "pages/Cgv" */))
const _33604b75 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))

const _53b8935d = () => interopDefault(import('../pages/HowToChooseWeddingDress.vue' /* webpackChunkName: "" */))
const _ad6ddfa4 = () => interopDefault(import('../pages/PendingAccount.vue' /* webpackChunkName: "" */))
const _70601d81 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "" */))
const _698a1276 = () => interopDefault(import('../pages/ContactUs.vue' /* webpackChunkName: "" */))
const _af7ada08 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _2e0aabcc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1c0e2f3a = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _649ce8b2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyHelpCenter.vue' /* webpackChunkName: "" */))
const _8982b82c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _68356d94 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4c846eba = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _433e6136 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _31a07f7a = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _cf9e30f4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _7df11a14 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _feaf398e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _e46b46e8 = () => interopDefault(import('../pages/Delivery.vue' /* webpackChunkName: "pages/Delivery" */))
const _b7e0701a = () => interopDefault(import('../pages/DeliveryInformations.vue' /* webpackChunkName: "pages/DeliveryInformations" */))
const _4e8cbf9c = () => interopDefault(import('../pages/Event.vue' /* webpackChunkName: "" */))
const _ed1bcaa4 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "" */))
const _388fc52d = () => interopDefault(import('../pages/FindOrder.vue' /* webpackChunkName: "pages/FindOrder" */))
const _014b008a = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "" */))
const _7e933dee = () => interopDefault(import('../pages/LegalNotice.vue' /* webpackChunkName: "pages/LegalNotice" */))
const _6963f6e6 = () => interopDefault(import('../pages/StoreLocator.vue' /* webpackChunkName: "" */))
const _ae93646e = () => interopDefault(import('../pages/OrderConfirmed.vue' /* webpackChunkName: "" */))
const _b51a44b2 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _f480071c = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _35881abc = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _6ff1925b = () => interopDefault(import('../pages/WeddingDress.vue' /* webpackChunkName: "" */))
const _4067ceda = () => interopDefault(import('../pages/SizeGuide.vue' /* webpackChunkName: "" */))
const _774a4d95 = () => interopDefault(import('../modules/wishlist/pages/Wishlist.vue' /* webpackChunkName: "" */))
const _a7fcbbf2 = () => interopDefault(import('../pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "pages/Checkout/ExternalCheckoutThankYou" */))
const _589ed929 = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _ca690898 = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-propos",
    component: _f1fec0b6,
    name: "about"
  }, {
    path: "/About",
    component: _f1fec0b6,
    name: "About"
  }, {
    path: "/b2b_en",
    component: _553dc7f7,
    name: "home___b2b_en"
  }, {
    path: "/b2bFR",
    component: _553dc7f7,
    name: "home___b2bFR"
  }, {
    path: "/Cart",
    component: _729c45b8,
    name: "Cart"
  }, {
    path: "/Cgu",
    component: _15964aae,
    name: "Cgu"
  }, {
    path: "/Cgv",
    component: _157a1bac,
    name: "Cgv"
  }, {
    path: "/checkout",
    component: _33604b75,
    name: "checkout"
  }, {
    path: "/collections",
    redirect: (to) => '/c',
    name: "Collections"
  }, {
    path: "/comment-choisir-sa-robe-de-mariee",
    component: _53b8935d,
    name: "how-to-choose-your-wedding-dress"
  }, {
    path: "/compte-en-attente",
    component: _ad6ddfa4,
    name: "pending-page"
  }, {
    path: "/conditions-generales-utilisateurs",
    component: _15964aae,
    name: "cgu"
  }, {
    path: "/conditions-generales-vente",
    component: _157a1bac,
    name: "cgv"
  }, {
    path: "/connexion",
    component: _70601d81,
    name: "login"
  }, {
    path: "/contact",
    component: _698a1276,
    name: "contact-us"
  }, {
    path: "/ContactUs",
    component: _698a1276,
    name: "ContactUs"
  }, {
    path: "/Cookie",
    component: _af7ada08,
    name: "Cookie"
  }, {
    path: "/customer",
    component: _2e0aabcc,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "addresses-details",
      component: _1c0e2f3a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "my-help-center",
      component: _649ce8b2,
      meta: {"titleLabel":"My Help Center"},
      name: "customer-my-help-center"
    }, {
      path: "my-newsletter",
      component: _8982b82c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-profile",
      component: _68356d94,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "my-reviews",
      component: _4c846eba,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "my-wishlist",
      component: _433e6136,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _31a07f7a,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "addresses-details/create",
      component: _cf9e30f4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _7df11a14,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history/:orderId",
      component: _feaf398e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/Delivery",
    component: _e46b46e8,
    name: "Delivery"
  }, {
    path: "/DeliveryInformations",
    component: _b7e0701a,
    name: "DeliveryInformations"
  }, {
    path: "/event",
    component: _4e8cbf9c,
    name: "event"
  }, {
    path: "/Event",
    component: _4e8cbf9c,
    name: "Event"
  }, {
    path: "/faq",
    component: _ed1bcaa4,
    name: "faq"
  }, {
    path: "/Faq",
    component: _ed1bcaa4,
    name: "Faq"
  }, {
    path: "/FindOrder",
    component: _388fc52d,
    name: "FindOrder"
  }, {
    path: "/Home",
    component: _553dc7f7,
    name: "Home"
  }, {
    path: "/HowToChooseWeddingDress",
    component: _53b8935d,
    name: "HowToChooseWeddingDress"
  }, {
    path: "/informations-de-livraison",
    component: _e46b46e8,
    name: "delivery"
  }, {
    path: "/inscription",
    component: _014b008a,
    name: "register"
  }, {
    path: "/LegalNotice",
    component: _7e933dee,
    name: "LegalNotice"
  }, {
    path: "/Login",
    component: _70601d81,
    name: "Login"
  }, {
    path: "/magasins",
    component: _6963f6e6,
    name: "store-locator"
  }, {
    path: "/order-confirmed",
    component: _ae93646e,
    name: "order-confirmed"
  }, {
    path: "/OrderConfirmed",
    component: _ae93646e,
    name: "OrderConfirmed"
  }, {
    path: "/Page",
    component: _b51a44b2,
    name: "Page"
  }, {
    path: "/panier",
    component: _729c45b8,
    name: "cart"
  }, {
    path: "/PendingAccount",
    component: _ad6ddfa4,
    name: "PendingAccount"
  }, {
    path: "/privacypolicy",
    component: _f480071c,
    name: "legal-notice"
  }, {
    path: "/privacypolicy",
    component: _f480071c,
    name: "privacy"
  }, {
    path: "/PrivacyPolicy",
    component: _f480071c,
    name: "PrivacyPolicy"
  }, {
    path: "/Register",
    component: _014b008a,
    name: "Register"
  }, {
    path: "/reset-password",
    component: _35881abc,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/ResetPassword",
    component: _35881abc,
    name: "ResetPassword"
  }, {
    path: "/retrouver-ma-commande",
    component: _388fc52d,
    name: "find-order"
  }, {
    path: "/robe-de-mariee",
    component: _6ff1925b,
    name: "wedding-dress"
  }, {
    path: "/sizeguide",
    component: _4067ceda,
    name: "size-guide"
  }, {
    path: "/SizeGuide",
    component: _4067ceda,
    name: "SizeGuide"
  }, {
    path: "/StoreLocator",
    component: _6963f6e6,
    name: "StoreLocator"
  }, {
    path: "/WeddingDress",
    component: _6ff1925b,
    name: "WeddingDress"
  }, {
    path: "/wishlist",
    component: _774a4d95,
    name: "wishlist"
  }, {
    path: "/b2b_en/a-propos",
    component: _f1fec0b6,
    name: "about___b2b_en"
  }, {
    path: "/b2b_en/About",
    component: _f1fec0b6,
    name: "About___b2b_en"
  }, {
    path: "/b2b_en/Cart",
    component: _729c45b8,
    name: "Cart___b2b_en"
  }, {
    path: "/b2b_en/Cgu",
    component: _15964aae,
    name: "Cgu___b2b_en"
  }, {
    path: "/b2b_en/Cgv",
    component: _157a1bac,
    name: "Cgv___b2b_en"
  }, {
    path: "/b2b_en/checkout",
    component: _33604b75,
    name: "checkout___b2b_en"
  }, {
    path: "/b2b_en/comment-choisir-sa-robe-de-mariee",
    component: _53b8935d,
    name: "how-to-choose-your-wedding-dress___b2b_en"
  }, {
    path: "/b2b_en/compte-en-attente",
    component: _ad6ddfa4,
    name: "pending-page___b2b_en"
  }, {
    path: "/b2b_en/conditions-generales-utilisateurs",
    component: _15964aae,
    name: "cgu___b2b_en"
  }, {
    path: "/b2b_en/conditions-generales-vente",
    component: _157a1bac,
    name: "cgv___b2b_en"
  }, {
    path: "/b2b_en/connexion",
    component: _70601d81,
    name: "login___b2b_en"
  }, {
    path: "/b2b_en/contact",
    component: _698a1276,
    name: "contact-us___b2b_en"
  }, {
    path: "/b2b_en/ContactUs",
    component: _698a1276,
    name: "ContactUs___b2b_en"
  }, {
    path: "/b2b_en/Cookie",
    component: _af7ada08,
    name: "Cookie___b2b_en"
  }, {
    path: "/b2b_en/customer",
    component: _2e0aabcc,
    meta: {"titleLabel":"My Account"},
    name: "customer___b2b_en",
    children: [{
      path: "addresses-details",
      component: _1c0e2f3a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___b2b_en"
    }, {
      path: "my-help-center",
      component: _649ce8b2,
      meta: {"titleLabel":"My Help Center"},
      name: "customer-my-help-center___b2b_en"
    }, {
      path: "my-newsletter",
      component: _8982b82c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___b2b_en"
    }, {
      path: "my-profile",
      component: _68356d94,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___b2b_en"
    }, {
      path: "my-reviews",
      component: _4c846eba,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___b2b_en"
    }, {
      path: "my-wishlist",
      component: _433e6136,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___b2b_en"
    }, {
      path: "order-history",
      component: _31a07f7a,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___b2b_en"
    }, {
      path: "addresses-details/create",
      component: _cf9e30f4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___b2b_en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _7df11a14,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___b2b_en"
    }, {
      path: "order-history/:orderId",
      component: _feaf398e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___b2b_en"
    }]
  }, {
    path: "/b2b_en/Delivery",
    component: _e46b46e8,
    name: "Delivery___b2b_en"
  }, {
    path: "/b2b_en/DeliveryInformations",
    component: _b7e0701a,
    name: "DeliveryInformations___b2b_en"
  }, {
    path: "/b2b_en/event",
    component: _4e8cbf9c,
    name: "event___b2b_en"
  }, {
    path: "/b2b_en/Event",
    component: _4e8cbf9c,
    name: "Event___b2b_en"
  }, {
    path: "/b2b_en/faq",
    component: _ed1bcaa4,
    name: "faq___b2b_en"
  }, {
    path: "/b2b_en/Faq",
    component: _ed1bcaa4,
    name: "Faq___b2b_en"
  }, {
    path: "/b2b_en/FindOrder",
    component: _388fc52d,
    name: "FindOrder___b2b_en"
  }, {
    path: "/b2b_en/Home",
    component: _553dc7f7,
    name: "Home___b2b_en"
  }, {
    path: "/b2b_en/HowToChooseWeddingDress",
    component: _53b8935d,
    name: "HowToChooseWeddingDress___b2b_en"
  }, {
    path: "/b2b_en/informations-de-livraison",
    component: _e46b46e8,
    name: "delivery___b2b_en"
  }, {
    path: "/b2b_en/inscription",
    component: _014b008a,
    name: "register___b2b_en"
  }, {
    path: "/b2b_en/LegalNotice",
    component: _7e933dee,
    name: "LegalNotice___b2b_en"
  }, {
    path: "/b2b_en/Login",
    component: _70601d81,
    name: "Login___b2b_en"
  }, {
    path: "/b2b_en/magasins",
    component: _6963f6e6,
    name: "store-locator___b2b_en"
  }, {
    path: "/b2b_en/order-confirmed",
    component: _ae93646e,
    name: "order-confirmed___b2b_en"
  }, {
    path: "/b2b_en/OrderConfirmed",
    component: _ae93646e,
    name: "OrderConfirmed___b2b_en"
  }, {
    path: "/b2b_en/Page",
    component: _b51a44b2,
    name: "Page___b2b_en"
  }, {
    path: "/b2b_en/panier",
    component: _729c45b8,
    name: "cart___b2b_en"
  }, {
    path: "/b2b_en/PendingAccount",
    component: _ad6ddfa4,
    name: "PendingAccount___b2b_en"
  }, {
    path: "/b2b_en/privacypolicy",
    component: _f480071c,
    name: "legal-notice___b2b_en"
  }, {
    path: "/b2b_en/privacypolicy",
    component: _f480071c,
    name: "privacy___b2b_en"
  }, {
    path: "/b2b_en/PrivacyPolicy",
    component: _f480071c,
    name: "PrivacyPolicy___b2b_en"
  }, {
    path: "/b2b_en/Register",
    component: _014b008a,
    name: "Register___b2b_en"
  }, {
    path: "/b2b_en/reset-password",
    component: _35881abc,
    alias: "/customer/account/createPassword",
    name: "reset-password___b2b_en"
  }, {
    path: "/b2b_en/ResetPassword",
    component: _35881abc,
    name: "ResetPassword___b2b_en"
  }, {
    path: "/b2b_en/retrouver-ma-commande",
    component: _388fc52d,
    name: "find-order___b2b_en"
  }, {
    path: "/b2b_en/robe-de-mariee",
    component: _6ff1925b,
    name: "wedding-dress___b2b_en"
  }, {
    path: "/b2b_en/sizeguide",
    component: _4067ceda,
    name: "size-guide___b2b_en"
  }, {
    path: "/b2b_en/SizeGuide",
    component: _4067ceda,
    name: "SizeGuide___b2b_en"
  }, {
    path: "/b2b_en/StoreLocator",
    component: _6963f6e6,
    name: "StoreLocator___b2b_en"
  }, {
    path: "/b2b_en/WeddingDress",
    component: _6ff1925b,
    name: "WeddingDress___b2b_en"
  }, {
    path: "/b2b_en/wishlist",
    component: _774a4d95,
    name: "wishlist___b2b_en"
  }, {
    path: "/b2bFR/a-propos",
    component: _f1fec0b6,
    name: "about___b2bFR"
  }, {
    path: "/b2bFR/About",
    component: _f1fec0b6,
    name: "About___b2bFR"
  }, {
    path: "/b2bFR/Cart",
    component: _729c45b8,
    name: "Cart___b2bFR"
  }, {
    path: "/b2bFR/Cgu",
    component: _15964aae,
    name: "Cgu___b2bFR"
  }, {
    path: "/b2bFR/Cgv",
    component: _157a1bac,
    name: "Cgv___b2bFR"
  }, {
    path: "/b2bFR/checkout",
    component: _33604b75,
    name: "checkout___b2bFR"
  }, {
    path: "/b2bFR/comment-choisir-sa-robe-de-mariee",
    component: _53b8935d,
    name: "how-to-choose-your-wedding-dress___b2bFR"
  }, {
    path: "/b2bFR/compte-en-attente",
    component: _ad6ddfa4,
    name: "pending-page___b2bFR"
  }, {
    path: "/b2bFR/conditions-generales-utilisateurs",
    component: _15964aae,
    name: "cgu___b2bFR"
  }, {
    path: "/b2bFR/conditions-generales-vente",
    component: _157a1bac,
    name: "cgv___b2bFR"
  }, {
    path: "/b2bFR/connexion",
    component: _70601d81,
    name: "login___b2bFR"
  }, {
    path: "/b2bFR/contact",
    component: _698a1276,
    name: "contact-us___b2bFR"
  }, {
    path: "/b2bFR/ContactUs",
    component: _698a1276,
    name: "ContactUs___b2bFR"
  }, {
    path: "/b2bFR/Cookie",
    component: _af7ada08,
    name: "Cookie___b2bFR"
  }, {
    path: "/b2bFR/customer",
    component: _2e0aabcc,
    meta: {"titleLabel":"My Account"},
    name: "customer___b2bFR",
    children: [{
      path: "addresses-details",
      component: _1c0e2f3a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___b2bFR"
    }, {
      path: "my-help-center",
      component: _649ce8b2,
      meta: {"titleLabel":"My Help Center"},
      name: "customer-my-help-center___b2bFR"
    }, {
      path: "my-newsletter",
      component: _8982b82c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___b2bFR"
    }, {
      path: "my-profile",
      component: _68356d94,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___b2bFR"
    }, {
      path: "my-reviews",
      component: _4c846eba,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___b2bFR"
    }, {
      path: "my-wishlist",
      component: _433e6136,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___b2bFR"
    }, {
      path: "order-history",
      component: _31a07f7a,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___b2bFR"
    }, {
      path: "addresses-details/create",
      component: _cf9e30f4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___b2bFR"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _7df11a14,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___b2bFR"
    }, {
      path: "order-history/:orderId",
      component: _feaf398e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___b2bFR"
    }]
  }, {
    path: "/b2bFR/Delivery",
    component: _e46b46e8,
    name: "Delivery___b2bFR"
  }, {
    path: "/b2bFR/DeliveryInformations",
    component: _b7e0701a,
    name: "DeliveryInformations___b2bFR"
  }, {
    path: "/b2bFR/event",
    component: _4e8cbf9c,
    name: "event___b2bFR"
  }, {
    path: "/b2bFR/Event",
    component: _4e8cbf9c,
    name: "Event___b2bFR"
  }, {
    path: "/b2bFR/faq",
    component: _ed1bcaa4,
    name: "faq___b2bFR"
  }, {
    path: "/b2bFR/Faq",
    component: _ed1bcaa4,
    name: "Faq___b2bFR"
  }, {
    path: "/b2bFR/FindOrder",
    component: _388fc52d,
    name: "FindOrder___b2bFR"
  }, {
    path: "/b2bFR/Home",
    component: _553dc7f7,
    name: "Home___b2bFR"
  }, {
    path: "/b2bFR/HowToChooseWeddingDress",
    component: _53b8935d,
    name: "HowToChooseWeddingDress___b2bFR"
  }, {
    path: "/b2bFR/informations-de-livraison",
    component: _e46b46e8,
    name: "delivery___b2bFR"
  }, {
    path: "/b2bFR/inscription",
    component: _014b008a,
    name: "register___b2bFR"
  }, {
    path: "/b2bFR/LegalNotice",
    component: _7e933dee,
    name: "LegalNotice___b2bFR"
  }, {
    path: "/b2bFR/Login",
    component: _70601d81,
    name: "Login___b2bFR"
  }, {
    path: "/b2bFR/magasins",
    component: _6963f6e6,
    name: "store-locator___b2bFR"
  }, {
    path: "/b2bFR/order-confirmed",
    component: _ae93646e,
    name: "order-confirmed___b2bFR"
  }, {
    path: "/b2bFR/OrderConfirmed",
    component: _ae93646e,
    name: "OrderConfirmed___b2bFR"
  }, {
    path: "/b2bFR/Page",
    component: _b51a44b2,
    name: "Page___b2bFR"
  }, {
    path: "/b2bFR/panier",
    component: _729c45b8,
    name: "cart___b2bFR"
  }, {
    path: "/b2bFR/PendingAccount",
    component: _ad6ddfa4,
    name: "PendingAccount___b2bFR"
  }, {
    path: "/b2bFR/privacypolicy",
    component: _f480071c,
    name: "legal-notice___b2bFR"
  }, {
    path: "/b2bFR/privacypolicy",
    component: _f480071c,
    name: "privacy___b2bFR"
  }, {
    path: "/b2bFR/PrivacyPolicy",
    component: _f480071c,
    name: "PrivacyPolicy___b2bFR"
  }, {
    path: "/b2bFR/Register",
    component: _014b008a,
    name: "Register___b2bFR"
  }, {
    path: "/b2bFR/reset-password",
    component: _35881abc,
    alias: "/customer/account/createPassword",
    name: "reset-password___b2bFR"
  }, {
    path: "/b2bFR/ResetPassword",
    component: _35881abc,
    name: "ResetPassword___b2bFR"
  }, {
    path: "/b2bFR/retrouver-ma-commande",
    component: _388fc52d,
    name: "find-order___b2bFR"
  }, {
    path: "/b2bFR/robe-de-mariee",
    component: _6ff1925b,
    name: "wedding-dress___b2bFR"
  }, {
    path: "/b2bFR/sizeguide",
    component: _4067ceda,
    name: "size-guide___b2bFR"
  }, {
    path: "/b2bFR/SizeGuide",
    component: _4067ceda,
    name: "SizeGuide___b2bFR"
  }, {
    path: "/b2bFR/StoreLocator",
    component: _6963f6e6,
    name: "StoreLocator___b2bFR"
  }, {
    path: "/b2bFR/WeddingDress",
    component: _6ff1925b,
    name: "WeddingDress___b2bFR"
  }, {
    path: "/b2bFR/wishlist",
    component: _774a4d95,
    name: "wishlist___b2bFR"
  }, {
    path: "/Checkout/ExternalCheckoutThankYou",
    component: _a7fcbbf2,
    name: "Checkout-ExternalCheckoutThankYou"
  }, {
    path: "/b2b_en/Checkout/ExternalCheckoutThankYou",
    component: _a7fcbbf2,
    name: "Checkout-ExternalCheckoutThankYou___b2b_en"
  }, {
    path: "/b2bFR/Checkout/ExternalCheckoutThankYou",
    component: _a7fcbbf2,
    name: "Checkout-ExternalCheckoutThankYou___b2bFR"
  }, {
    path: "/b2b_en/p/:id/:slug",
    component: _589ed929,
    name: "product___b2b_en"
  }, {
    path: "/b2bFR/p/:id/:slug",
    component: _589ed929,
    name: "product___b2bFR"
  }, {
    path: "/b2b_en/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _ca690898,
    name: "category___b2b_en"
  }, {
    path: "/b2bFR/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _ca690898,
    name: "category___b2bFR"
  }, {
    path: "/b2b_en/:slug+",
    component: _b51a44b2,
    name: "page___b2b_en"
  }, {
    path: "/b2bFR/:slug+",
    component: _b51a44b2,
    name: "page___b2bFR"
  }, {
    path: "/p/:id/:slug/",
    component: _589ed929,
    name: "product"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _ca690898,
    name: "category"
  }, {
    path: "/",
    component: _553dc7f7,
    name: "home"
  }, {
    path: "/:slug+",
    component: _b51a44b2,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
