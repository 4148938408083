import { ref } from '@nuxtjs/composition-api';
import axios from 'axios';
import { useTokenStore } from './store/useToken';
import type { UseTokenInterface } from './useToken';

export function useToken(): UseTokenInterface {
  const adminTokenStore = useTokenStore();
  const baseUrlStore = process.env.VSF_STORE_URL;

  const verifyTokenExpired = (token) => {
    const arrayToken = token.split('.');
    const base64 = JSON.parse(atob(arrayToken[1]));
    const jsonPayload = Math.floor(new Date().getTime() / 1000) >= base64?.exp;
    return jsonPayload;
  };

  const getTokenStore = async () => {
    if (adminTokenStore.token === null) {
      const adminToken = await axios.post(`${baseUrlStore}getAdminToken`);
      adminTokenStore.token = adminToken.data;
      return adminToken.data;
    }
    const tokenExpired = verifyTokenExpired(adminTokenStore.token);
    if (tokenExpired) {
      const adminToken = await axios.post(`${baseUrlStore}getAdminToken`);
      adminTokenStore.token = adminToken.data;
      return adminToken.data;
    }
    return adminTokenStore.token;
  };

  return {
    getTokenStore,
  };
}

export default useToken;