






















import { useUiState } from '../../../composables/useUiState/index';
import HeaderModal from './CommonsModal/HeaderModal.vue';
import AccountInModal from './CommonsModal/AccountInModal.vue';
import OrderTrackInModal from '../../General/Modal/CommonsModal/OrderTrackInModal.vue';
import CartModal from './CommonsModal/CartModal.vue';
import OrderTrack from './CommonsModal/OrderTrack.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    toggleLoginModal,
    currentViewInModal,
    setCurrentViewInModal
  } = useUiState();
  const handleSwitchView = (viewName: string) => {
    setCurrentViewInModal(viewName);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if ((event.target as HTMLElement)?.classList.contains('container-account__modal') && currentViewInModal.value !== 'CartModal' && currentViewInModal.value !== 'OrderTrackInModal') {
      emit('close');
    }
  };
  return {
    toggleLoginModal,
    currentViewInModal,
    handleSwitchView,
    handleClickOutside
  };
};
__sfc_main.components = Object.assign({
  OrderTrackInModal,
  HeaderModal,
  AccountInModal,
  OrderTrack,
  CartModal
}, __sfc_main.components);
export default __sfc_main;
