

















import ItemAccount from './ItemAccountInModal.vue';
import iconLogOut from '~/assets/icons/icon-log-out.svg';
import useUser from '~/modules/customer/composables/useUser';
import { useContext, useRouter, onMounted, ref } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    app
  } = useContext();
  const router = useRouter();
  const {
    toggleLoginModal
  } = useUiState();
  const {
    logout,
    load
  } = useUser();
  const items = [{
    text: 'Commandes et retours',
    icon: 'icon-cart',
    link: "/customer/order-history"
  }, {
    text: 'Favoris',
    icon: 'icon-heart',
    link: "/customer/my-wishlist"
  }, {
    text: 'Informations personnelles',
    icon: 'icon-infos',
    link: "/customer/my-profile"
  }, {
    text: "Carnet d'adresses",
    icon: 'icon-address',
    link: "/customer/addresses-details"
  }, {
    text: "Centre d'aide",
    icon: 'icon-help',
    link: "/customer/my-help-center"
  }];
  const handleLogOut = async () => {
    await logout();
    const loginRoute = app.localeRoute({
      name: 'login'
    });
    router.push(loginRoute);
    toggleLoginModal(false);
  };
  const user = ref<{
    firstname?: string;
  }>({});
  onMounted(async () => {
    user.value = await load();
  });
  return {
    iconLogOut,
    items,
    handleLogOut,
    user
  };
};
__sfc_main.components = Object.assign({
  ItemAccount
}, __sfc_main.components);
export default __sfc_main;
