import getParentSkuException from '~/exceptions/getParentSkuByChild.exception';
import { FetchResult } from '@apollo/client/core';
import axios from 'axios'

export class ParentSku {
    constructor() {}
        readonly baseUrlOrderHistoryImage = process.env.VSF_STORE_URL;
        readonly baseUrlStore = process.env.VSF_STORE_URL;
        readonly headers = {
            "Content-Type" : 'application/json'
        }

        private async getParentSku(child_sku: Readonly<string>): Promise<Readonly<FetchResult>> {

            try {
                const adminToken = await axios.post(`${this.baseUrlStore}getAdminToken`);
                const response = await axios.get(`${ this.baseUrlStore }getChildSku?sku=${child_sku}&adminToken=${adminToken.data}`);
              return response;
            }
            catch (error){
                throw new getParentSkuException(JSON.stringify(error));
            }
        } 

        public async getParentSkuByChild(child_sku: Readonly<string>): Promise<Readonly<any>> {
            try {
                const ParentSkuByChild = await this.getParentSku(child_sku);
                return ParentSkuByChild;
            } catch(error) {
                throw new getParentSkuException(JSON.stringify(error))
            }
        }
}